import { useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { Box, Button, ButtonGroup, Collapse, Paper, SxProps, Typography } from "@mui/material";
import SalesTable from "./SalesTable";
import MixedChart from "./charts/MixedChart";
import { FlexBox } from "../styles";
import HoursIcon from "@mui/icons-material/AddAlarm";
import TableIcon from "@mui/icons-material/TableRows";
import SalesTableForm from "./SalesTableForm";
import { SEGMENTS_ID } from "../helpers/constants";
import { IChartSerie, ISegmentItem, ISegmentsMapper } from "../types";
import AppStorage from "../services/AppStorage";
import { formatDistanceToNow } from "date-fns";
import { getLocale } from "../helpers/functions";

const btnStyle: SxProps = {
  textTransform: "none",
  px: 2,
  mx: 1,
};

function getVariant(value: string, selected: string) {
  if (value === selected) return "contained";
  return "outlined";
}

function noData(data?: ISegmentItem[]) {
  if (data && data.length > 0) return false;
  else return true;
}

function getLastRequest() {
  const timestamp = AppStorage.getSalesRequestDate();
  if (timestamp) return formatDistanceToNow(new Date(timestamp), { locale: getLocale() });
  else return "-";
}

interface IFilteredSeries {
  [key: string]: IChartSerie[];
}

interface Props {
  segments: ISegmentsMapper;
  categories: string[];
  gcsSeries: IChartSerie[];
  hsSeries: IChartSerie[];
  ipSeries: IChartSerie[];
}

const SegmentsDataDashboard = ({ segments, categories, gcsSeries, hsSeries, ipSeries }: Props) => {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<ISegmentItem[]>(segments["TOTALS"] || []);
  const [selectedSegment, setSelectedSegment] = useState<string>("TOTALS");
  const [selectedView, setSelectedView] = useState<"table" | "form">("table");
  const [selectedChart, setSelectedChart] = useState<string>("gcs");
  const [lastRequest, setLastRequest] = useState<string>(getLastRequest());

  const availableSegments = useMemo(() => Object.keys(segments).map((key) => key), [segments]);

  useEffect(() => {
    const requestTimer = setInterval(() => {
      setLastRequest(getLastRequest());
    }, 60000);

    return () => clearInterval(requestTimer);
  }, []);

  useEffect(() => {
    setTableData(segments[selectedSegment] || []);
  }, [segments, selectedSegment]);

  const filteredSeries: IFilteredSeries = useMemo(() => {
    return {
      gcs: gcsSeries.filter((item) => item.name.includes(selectedSegment)),
      hours: hsSeries.filter((item) => item.name.includes(selectedSegment)),
      ip: ipSeries.filter((item) => item.name.includes(selectedSegment)),
    };
  }, [selectedSegment, gcsSeries, hsSeries, ipSeries]);

  const toggleCollapse = (currentView: "table" | "form") => {
    if (currentView === "table") setSelectedView("form");
    if (currentView === "form") setSelectedView("table");
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FlexBox sx={{ justifyContent: "space-between" }}>
          <Button
            // disabled={tableData.length === 0}
            disabled
            startIcon={selectedView === "table" ? <HoursIcon /> : <TableIcon />}
            size="small"
            sx={{ textTransform: "none", px: 2, mr: 3, textWrap: "nowrap" }}
            variant="contained"
            onClick={() => toggleCollapse(selectedView)}
          >
            {selectedView === "table" ? "Cargar Horas" : "Ver Tabla"}
          </Button>
          <Box component={Paper} sx={{ width: "100%" }}>
            <ButtonGroup fullWidth>
              {SEGMENTS_ID.map((item) => (
                <Button
                  key={item}
                  disabled={noData(segments[item])}
                  variant={getVariant(item, selectedSegment)}
                  size="small"
                  sx={{ textTransform: "none" }}
                  onClick={() => setSelectedSegment(item)}
                >
                  {item}
                </Button>
              ))}
            </ButtonGroup>
          </Box>
        </FlexBox>
      </Grid>
      <Grid item lg={6.5} xs={12}>
        <Box component={Paper}>
          <Collapse in={selectedView === "table"}>
            <SalesTable rows={tableData} />
          </Collapse>
          <Collapse in={selectedView === "form"}>
            <SalesTableForm
              hours={categories}
              rows={tableData}
              segments={availableSegments}
              selectedSegment={selectedSegment}
              toggleCollapse={toggleCollapse}
            />
          </Collapse>
        </Box>
      </Grid>
      <Grid item lg={5.5} xs={12}>
        <Box component={Paper} p={2}>
          <MixedChart
            title={t("labels.proyectionChartTitle").toString()}
            series={filteredSeries[selectedChart]}
            categories={categories}
            height={280}
          />
          <FlexBox sx={{ placeContent: "center" }}>
            {/* ["gcs", "hours", "ip"].map */}
            {["gcs", "ip"].map((e) => (
              <Button
                key={e}
                size="small"
                sx={btnStyle}
                variant={getVariant(e, selectedChart)}
                onClick={() => setSelectedChart(e)}
              >
                {e}
              </Button>
            ))}
          </FlexBox>
        </Box>
        <FlexBox sx={{ justifyContent: "flex-end", mt: 1 }}>
          <Typography variant="caption">
            {`${t("labels.lastUpdatedAgo")} `}
            {lastRequest}
          </Typography>
        </FlexBox>
      </Grid>
    </Grid>
  );
};

export default SegmentsDataDashboard;
