import React from "react";
import { Box, Checkbox, FormControlLabel, TextField, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { IActivityItem, INoteItem } from "../../types";
import CheckList from "../../components/CheckList";
import { ActionButton, SecondaryText } from "../../styles";
import { useDispatch, useSelector } from "react-redux";
import { addNoteRequested } from "../../redux/activities/notes/actions";
import { posShiftDataSelector } from "../../redux/activities/checklists/selectors";
import { getActivityTypeDescription, getISOString } from "../../helpers/functions";
import { NoteFormData } from "../../components/modals/NoteFormModal";
import { isAppOnlineSelector } from "../../redux/app/selectors";
import { activityTypesDataSelector } from "../../redux/storePlan/selectors";
import { loadingNoteSelector } from "../../redux/activities/notes/selectors";
import { setPosShiftRequested } from "../../redux/activities/checklists/actions";
import { t } from "i18next";

/**
 * Obtiene los checklist de pos-turno.
 * Se envía y guarda el checklist completo.
 * Se adjunta una nota de pos-turno al finalizar turno.
 * @activityType POS
 */

const PosShift = () => {
  const dispatch = useDispatch();
  const isAppOnline = useSelector(isAppOnlineSelector);
  const activityTypes = useSelector(activityTypesDataSelector);
  const posShiftData = useSelector(posShiftDataSelector);
  const loading = useSelector(loadingNoteSelector);

  const initialState: NoteFormData = {
    title: t("pages.posShift.NoteTitle").toString(),
    priority: false,
    private: false,
    state: "pending",
  };
  const [formData, setFormData] = React.useState<NoteFormData>(initialState);

  const handleChecklistSubmit = (data: IActivityItem[]) => {
    dispatch(setPosShiftRequested(data));
  };

  const handleChange = (value: string | boolean, field: string) => {
    let newData = { ...formData };
    switch (field) {
      case "prioriry":
        newData[field] = value === "true" ? true : false;
        break;
      default:
        newData[field] = value;
        break;
    }
    setFormData(newData);
  };

  const onSubmit = () => {
    const newData: Partial<INoteItem> = {
      ...formData,
      activityTypesId: "POS",
      createdDate: getISOString(),
      lastUpdateDate: getISOString(),
    };
    dispatch(addNoteRequested(newData));
  };

  return (
    <Container maxWidth={false}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <CheckList
            title={getActivityTypeDescription(activityTypes["POS"])}
            data={posShiftData}
            onSubmit={handleChecklistSubmit}
            showProgress
            hideAddNote
          />
        </Grid>
        <Grid
          item
          xs={4}
          component="form"
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <Box>
            <Typography variant="h5">{t("pages.posShift.title")}</Typography>
            <SecondaryText>{t("pages.posShift.subtitle")}</SecondaryText>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Typography variant="h6" my={2}>
                {t("pages.posShift.NoteTitle")}
              </Typography>
              <FormControlLabel
                disabled={!isAppOnline}
                control={
                  <Checkbox
                    onChange={(e, checked) => handleChange(checked, "priority")}
                    checked={formData.priority || false}
                    color="secondary"
                  />
                }
                label={<Typography variant="body1">{t("labels.highPriority")}</Typography>}
              />
            </Box>
            <TextField
              required
              fullWidth
              id="description"
              placeholder={t("labels.descriptionPH").toString()}
              value={formData.description || ""}
              onChange={(e) => handleChange(e.target.value, "description")}
              size="small"
              multiline
              rows={5}
              disabled={!isAppOnline}
              sx={{
                backgroundColor: (theme) => theme.palette.background.paper,
                borderRadius: 2,
                mb: 2,
              }}
            />
            <ActionButton
              type="submit"
              variant="contained"
              fullWidth
              loading={loading}
              disabled={loading || !isAppOnline}
            >
              {t("pages.posShift.endShift")}
            </ActionButton>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PosShift;
