import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CheckList from "../../components/CheckList";
import {
  ActivityType,
  IAreaChecklist,
  IAreaType,
  IActivityItem,
  IStatProgress,
} from "../../types";
import { useDispatch, useSelector } from "react-redux";
import { areasDataSelector } from "../../redux/admin/areas/selectors";
import { setAreasChecklistRequested } from "../../redux/activities/checklists/actions";
import AreaCard from "../../components/cards/AreaCard";
import { areaListContainer } from "../../styles";
import { getAreaDescription, getActivityTypeDescription } from "../../helpers/functions";
import { IChkState, checklistDataSelector } from "../../redux/activities/checklists/selectors";
import { activityTypesDataSelector } from "../../redux/storePlan/selectors";
import { useLocation } from "react-router-dom";
import { routes } from "../../router/routes";

const getProgress = (checklist: IActivityItem[]): IStatProgress => {
  let progress: IStatProgress = { total: checklist?.length || 0, pending: 0, finished: 0 };
  checklist?.forEach((e) => {
    if (e.checked) {
      progress.finished++;
    } else {
      progress.pending++;
    }
  });
  return progress;
};

function getChecklistType(location: string): ActivityType {
  if (location.includes(routes.main.opening.path)) return ActivityType.OPENING;
  if (location.includes(routes.main.closing.path)) return ActivityType.CLOSING;

  return ActivityType.CHECKLIST;
}

/**
 * Obtiene los checklist de cierre por @area habilitada.
 * Se envían y guardan los checklist completos de cada área modificada.
 * @activityType CLO
 */

const AreaChecklist = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const areas = useSelector(areasDataSelector);
  const activityType = getChecklistType(location.pathname);
  const checklistData = useSelector((state) =>
    checklistDataSelector(state as IChkState, activityType)
  );
  const activityTypes = useSelector(activityTypesDataSelector);
  const [enabledAreas, setEnabledAreas] = React.useState<IAreaType[]>([]);
  const [changes, setChanges] = React.useState<IAreaChecklist>({});
  const [areaFlag, setAreaFlag] = React.useState<string[]>([]);
  const [selectedArea, setSelectedArea] = React.useState<IAreaType>();
  const [seletedChecklist, setSelectedChecklist] = React.useState<IActivityItem[]>([]);
  const [progress, setProgress] = React.useState<IStatProgress>(getProgress(seletedChecklist));

  React.useEffect(() => {
    const keys = Object.keys(checklistData).map((key) => key);
    const filteredAreas = areas.filter((a) => keys.includes(a.areaTypeId));
    if (filteredAreas.length > 0) {
      setEnabledAreas(filteredAreas);
      setSelectedArea(filteredAreas[0]);
      setChanges({ ...checklistData });
      setSelectedChecklist(checklistData[filteredAreas[0].areaTypeId] || []);
    }
    // eslint-disable-next-line
  }, [areas, checklistData]);

  const handleChanges = (progress: IStatProgress, data?: IActivityItem[]) => {
    setProgress(progress);
    if (selectedArea && data) {
      const key = selectedArea.areaTypeId;
      let newState = { ...changes };
      newState[key] = [...data];
      setChanges(newState);
      if (!areaFlag.includes(key)) setAreaFlag((state) => [...state, key]);
    }
    if (progress.finished === progress.total && selectedArea && data) {
      const index = enabledAreas.indexOf(selectedArea);
      if (index >= 0 && index < enabledAreas.length) {
        handleAreaClick(enabledAreas[index + 1]);
      }
    }
  };

  function isSelected(id: string) {
    return selectedArea?.areaTypeId === id;
  }

  const handleAreaClick = (item: IAreaType) => {
    setProgress(getProgress(changes[item.areaTypeId] || []));
    setSelectedChecklist(changes[item.areaTypeId] || []);
    setSelectedArea(item);
  };

  const handleSubmit = (data: IActivityItem[]) => {
    dispatch(setAreasChecklistRequested(changes, activityType, areaFlag));
  };

  return (
    <Container maxWidth={false}>
      <Grid container spacing={2}>
        <Grid item xs={3} sx={areaListContainer}>
          <Grid container spacing={1}>
            {enabledAreas.map(
              (i) =>
                getProgress(changes[i.areaTypeId]).total > 0 && (
                  <Grid item xs={12} key={i.areaTypeId}>
                    <AreaCard
                      area={i}
                      selected={isSelected(i.areaTypeId)}
                      isSingleArea={enabledAreas.length === 1}
                      progress={
                        isSelected(i.areaTypeId) ? progress : getProgress(changes[i.areaTypeId])
                      }
                      onClick={handleAreaClick}
                    />
                  </Grid>
                )
            )}
          </Grid>
        </Grid>
        <Grid item xs={9}>
          {selectedArea && (
            <CheckList
              title={`${getActivityTypeDescription(
                activityTypes[activityType]
              )} - ${getAreaDescription(selectedArea)}`}
              data={seletedChecklist}
              onSubmit={handleSubmit}
              getProgress={handleChanges}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default AreaChecklist;
