import { useEffect, useMemo, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import CurrentShiftCard from "../../components/cards/CurrentShiftCard";
import ProgressStatCard from "../../components/cards/ProgressStatCard";
import { useNavigate } from "react-router-dom";
import { routes } from "../../router/routes";
import { Box, Chip, List, ListItem, Paper, SxProps, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  areasChecklistDataSelector,
  closingDataSelector,
  openingDataSelector,
  posShiftDataSelector,
  preShiftDataSelector,
} from "../../redux/activities/checklists/selectors";
import {
  objectivesDataSelector,
  sosDtReportSelector,
  sosFcReportSelector,
  sosMfyReportSelector,
} from "../../redux/activities/objectives/selectors";
import { hourlyReviewDataSelector } from "../../redux/activities/rev/selectors";
import CircularStatCard from "../../components/cards/CircularStatCard";
import TourHistory from "../../components/TourHistory";
import { formatHRTimes } from "../activities/HourlyReview";
import { filtersSelector } from "../../redux/admin/masterdata/selectors";
import CompleteIcon from "@mui/icons-material/CheckCircle";
import UncompleteIcon from "@mui/icons-material/Cancel";
import ObjIcon from "@mui/icons-material/Queue";
import {
  areasChecklistRawFormatter,
  compareObjectives,
  getActivityTypeDescription,
  getObjectiveDescription,
} from "../../helpers/functions";
import { ActivityType, IActivityItem, IObjectiveItem } from "../../types";
import { activityTypesDataSelector } from "../../redux/storePlan/selectors";
import { ActionButton } from "../../styles";
import { IProgress } from "../../components/cards/ProgressCard";
import { calcObjectivesProgress } from "../activities/Objectives";

const hrContainer: SxProps = {
  cursor: "pointer",
  width: "100%",
  pt: 1,
  textAlign: "center",
  "&:hover": {
    opacity: 0.7,
  },
};

function getProgress(data: IActivityItem[]) {
  return {
    total: data.length,
    finished: data.filter((e) => e.checked === true).length,
    pending: data.filter((e) => e.checked === false).length,
  };
}

function isObjAchieved(
  obj: IObjectiveItem,
  dt?: number | null,
  fc?: number | null,
  mfy?: number | null
) {
  const objValue = parseInt(obj.objectiveDescription);
  switch (obj.invokedService) {
    case "SOS_DT":
      if (!isNaN(objValue) && dt && objValue > dt) return true;
      else return false;
    case "SOS_FC":
      if (!isNaN(objValue) && fc && objValue > fc) return true;
      else return false;
    case "SOS_MFY":
      if (!isNaN(objValue) && mfy && objValue > mfy) return true;
      else return false;
    default:
      return obj.objectiveAchieved;
  }
}

const ActivitiesDashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filters = useSelector(filtersSelector);
  const activityTypes = useSelector(activityTypesDataSelector);
  const opening = useSelector(openingDataSelector);
  const closing = useSelector(closingDataSelector);
  const preShift = useSelector(preShiftDataSelector);
  const checklist = useSelector(areasChecklistDataSelector);
  const posShift = useSelector(posShiftDataSelector);
  const objectives = useSelector(objectivesDataSelector);
  const sosDtReport = useSelector(sosDtReportSelector);
  const sosFcReport = useSelector(sosFcReportSelector);
  const sosMfyReport = useSelector(sosMfyReportSelector);
  const hourlyReviews = useSelector(hourlyReviewDataSelector);
  const formattedHourlyReviews = formatHRTimes(hourlyReviews, filters.businessDate);

  const currentObjectives: IObjectiveItem[] = useMemo(
    () => objectives.map((o) => ({ ...o })).sort(compareObjectives),
    [objectives]
  );
  const [objectivesProgress, setObjectivesProgress] = useState<IProgress>(
    calcObjectivesProgress([])
  );

  useEffect(() => {
    if (currentObjectives.length > 0) {
      setObjectivesProgress(
        calcObjectivesProgress(
          currentObjectives,
          sosDtReport?.oepe,
          sosFcReport?.r2p,
          sosMfyReport?.tts
        )
      );
    }
    // eslint-disable-next-line
  }, [currentObjectives, sosDtReport, sosFcReport, sosMfyReport, dispatch]);

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item container alignSelf={"flex-start"} spacing={3} xs={12} lg={9}>
          <Grid item xs={6}>
            <CurrentShiftCard />
          </Grid>
          <Grid item xs={6}>
            <Grid container component={Paper}>
              <Grid item xs={5} onClick={() => navigate(routes.main.objectives.path)}>
                <CircularStatCard
                  title={getActivityTypeDescription(activityTypes[ActivityType.OBJETIVES])}
                  progress={objectivesProgress}
                />
              </Grid>
              <Grid item xs={7} alignSelf={"center"}>
                <Box sx={{ height: 252, overflow: "auto", alignContent: "center", pt: 2 }}>
                  {currentObjectives.length === 0 && (
                    <Box sx={{ textAlign: "center", opacity: 0.9 }}>
                      <UncompleteIcon color="error" sx={{ height: 30, width: 30 }} />
                      <Typography variant="h6">{t("labels.noObjectives")}</Typography>
                      <ActionButton
                        startIcon={<ObjIcon />}
                        sx={{ mt: 1, px: 2 }}
                        variant="contained"
                        onClick={() => navigate(routes.main.objectives.path)}
                      >
                        {t("labels.addObjectives")}
                      </ActionButton>
                    </Box>
                  )}
                  <List sx={{ pt: 4 }}>
                    {currentObjectives.map((o) => (
                      <ListItem key={o.id}>
                        <Chip
                          icon={
                            isObjAchieved(
                              o,
                              sosDtReport?.oepe,
                              sosFcReport?.r2p,
                              sosMfyReport?.tts
                            ) ? (
                              <CompleteIcon color="success" />
                            ) : (
                              <UncompleteIcon color="error" />
                            )
                          }
                          label={getObjectiveDescription(o, "item")}
                          variant="filled"
                          size="small"
                          color={"default"}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item spacing={3} xs={12}>
            <Grid item xs={6}>
              <ProgressStatCard
                title={getActivityTypeDescription(activityTypes[ActivityType.OPENING])}
                progress={getProgress(areasChecklistRawFormatter(opening))}
                onClick={() => navigate(routes.main.opening.path)}
              />
            </Grid>
            <Grid item xs={6}>
              <ProgressStatCard
                title={getActivityTypeDescription(activityTypes[ActivityType.CLOSING])}
                progress={getProgress(areasChecklistRawFormatter(closing))}
                onClick={() => navigate(routes.main.closing.path)}
              />
            </Grid>
            <Grid item xs={4}>
              <ProgressStatCard
                title={getActivityTypeDescription(activityTypes[ActivityType.PRE_SHIFT])}
                progress={getProgress(preShift)}
                onClick={() => navigate(routes.main.preShift.path)}
              />
            </Grid>
            <Grid item xs={4}>
              <ProgressStatCard
                title={getActivityTypeDescription(activityTypes[ActivityType.CHECKLIST])}
                progress={getProgress(areasChecklistRawFormatter(checklist))}
                onClick={() => navigate(routes.main.checklist.path)}
              />
            </Grid>
            <Grid item xs={4}>
              <ProgressStatCard
                title={getActivityTypeDescription(activityTypes[ActivityType.POS_SHIFT])}
                progress={getProgress(posShift)}
                onClick={() => navigate(routes.main.posShift.path)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Box
            component={Paper}
            sx={hrContainer}
            onClick={() => navigate(routes.main.hourlyReview.path)}
          >
            <Typography variant="h6">{t("pages.hourlyReview.tourHistory")}</Typography>
            <TourHistory steps={formattedHourlyReviews} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ActivitiesDashboard;
