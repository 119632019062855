import { Box, Grid, SxProps, Typography, styled } from "@mui/material";
import { IStatProgress } from "../../types";
import LinearProgressBar from "../LinearProgressBar";
import { useTranslation } from "react-i18next";

const ProgressBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

export interface IProgress {
  total: number;
  pending: number;
  finished: number;
}

interface Props {
  title: string;
  progress: IStatProgress;
  totalLabel?: string;
  finishedLabel?: string;
  pendingLabel?: string;
  hideTotal?: boolean;
}

const ProgressCard = ({
  title,
  progress,
  totalLabel,
  finishedLabel,
  pendingLabel,
  hideTotal,
}: Props) => {
  const { t } = useTranslation();
  const { total, finished, pending } = progress;

  const getProgress = () => {
    if (total === 0) return -1;
    return (finished * 100) / total;
  };

  return (
    <Grid container spacing={1} alignItems={"center"} justifyContent={"space-between"}>
      <Grid item xs={hideTotal ? 6 : 4}>
        <Typography variant="h6">{title}</Typography>
        <ProgressBox>
          <Typography>{t("labels.progress")}</Typography>
          <Typography sx={{ fontWeight: 500 }}>{`${finished}/${total}`}</Typography>
        </ProgressBox>
        <LinearProgressBar value={getProgress()} />
      </Grid>
      {!hideTotal && (
        <Grid item xs={2}>
          <StatCard title={totalLabel || t("labels.total")} value={total} color="secondary.main" />
        </Grid>
      )}
      <Grid item xs={2}>
        <StatCard
          title={finishedLabel || t("labels.finisheds")}
          value={finished}
          color="success.light"
        />
      </Grid>
      <Grid item xs={2}>
        <StatCard
          title={pendingLabel || t("labels.pendings")}
          value={pending}
          color="error.light"
        />
      </Grid>
    </Grid>
  );
};

interface SCProps {
  title: string;
  value: number;
  color: string;
}

const StatCard = ({ title, value, color }: SCProps) => {
  const statBox: SxProps = {
    borderLeft: 4,
    borderColor: color,
  };

  return (
    <Box>
      <Typography sx={{ fontWeight: 500, opacity: 0.6 }}>{title}</Typography>
      <Box sx={statBox}>
        <Typography variant="h5" ml={1}>
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProgressCard;
