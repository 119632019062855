import React from "react";
import { Box, Collapse, Grid, Paper, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import TourStepper from "../../components/TourStepper";
import TourHistory from "../../components/TourHistory";
import { IHourlyReviewItem, IHourlyReviewState } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import {
  hourlyReviewConfirmedSelector,
  hourlyReviewDataSelector,
  loadingHourlyReviewSelector,
} from "../../redux/activities/rev/selectors";
import TimerProgress from "../../components/TimerProgress";
import HourlyReviewConfirm from "../../components/HourlyReviewConfirm";
import {
  setHourlyReviewState,
  updateHourlyReviewRequested,
} from "../../redux/activities/rev/actions";
import { useTranslation } from "react-i18next";
import { SecondaryText } from "../../styles";
import NoteFab from "../../components/NoteFab";
import { isAfter, isSameDay } from "date-fns";
import { filtersSelector } from "../../redux/admin/masterdata/selectors";
import { hourlyReviewTypesDataSelector } from "../../redux/storePlan/selectors";
import { compareReviewHour } from "../../helpers/functions";

export type TimeRange = "beforeRange" | "inRange" | "afterRange" | "outOfRange";

export interface FormattedHourlyReview {
  item: IHourlyReviewItem;
  date: Date;
}

export function formatHRTimes(hourlyReviews: IHourlyReviewItem[], businessDate?: string) {
  const formattedData: FormattedHourlyReview[] = [];
  const date = businessDate ? new Date(businessDate) : new Date();

  hourlyReviews.forEach((e) => {
    formattedData.push({ item: { ...e }, date });
  });

  return formattedData.sort(compareReviewHour);
}

export function getTimeRangeByDate(data?: FormattedHourlyReview, hour?: number): TimeRange {
  const today = new Date();
  const currentHour = hour || today.getHours();

  if (!data) return "outOfRange";

  if (isSameDay(today, data.date)) {
    if (data.item.reviewHour === currentHour) return "inRange";
    if (data.item.reviewHour < currentHour) return "beforeRange";
    if (data.item.reviewHour > currentHour) return "afterRange";
  } else if (isAfter(data.date, today)) return "afterRange";
  else return "beforeRange";

  return "outOfRange";
}

export function getCurrentHour(data: FormattedHourlyReview[], hour: number) {
  return data.find((e) => e.item.reviewHour === hour);
}

/**
 * Obtiene los datos de recorrido por hora según: día, turno y horarios.
 * Utiliza los datos maestros para mostrar los pasos en cada sección.
 * Se envía y guarda el recorrido completado, en caso contrareo queda como incompleto para la hora.
 * @activityType REV
 */

const HourlyReview = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filters = useSelector(filtersSelector);
  const hourlyReviews = useSelector(hourlyReviewDataSelector);
  const hourlyReviewState = useSelector(hourlyReviewConfirmedSelector);
  const hourlyReviewTypes = useSelector(hourlyReviewTypesDataSelector);
  const loading = useSelector(loadingHourlyReviewSelector);
  const hourRange = `(${hourlyReviewState.hour}:00 - ${hourlyReviewState.hour + 1}:00)`;
  const formattedHourlyReviews = formatHRTimes(hourlyReviews, filters.businessDate);
  const timeRange = getTimeRangeByDate(
    getCurrentHour(formattedHourlyReviews, hourlyReviewState.hour)
  );
  const disableConfirm = timeRange !== "inRange";

  const handleConfirm = () => {
    const value: IHourlyReviewState = { ...hourlyReviewState, confirmed: true };
    dispatch(setHourlyReviewState({ value }));
  };

  const handleComplete = () => {
    const hourData = hourlyReviews.find((e) => e.reviewHour === new Date().getHours());
    if (hourData) dispatch(updateHourlyReviewRequested({ ...hourData, checked: true }));
  };

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={5} lg={4}>
          <TimerProgress
            description={t("labels.remainingTime").toString()}
            size={135}
            thickness={3}
            timeRange={timeRange}
            showHourRange
          />
          <Box sx={{ textAlign: "center", m: 1 }}>
            <Typography variant="h5">{t("pages.hourlyReview.tourHistory")}</Typography>
          </Box>
          <Box component={Paper} p={2}>
            <TourHistory steps={formattedHourlyReviews} />
          </Box>
        </Grid>
        <Grid item xs={7} lg={8}>
          <Collapse in={!hourlyReviewState.confirmed}>
            <HourlyReviewConfirm
              descriptions={hourlyReviewTypes?.confirm}
              disabled={disableConfirm}
              onConfirm={handleConfirm}
            />
          </Collapse>
          <Collapse in={hourlyReviewState.confirmed}>
            <Box component={Paper} p={2}>
              <Box sx={{ display: "flex", alignItems: "baseline" }}>
                <Typography variant="h5">{t("pages.hourlyReview.hourTour")}</Typography>
                <SecondaryText variant="h6" ml={1.5}>
                  {hourRange}
                </SecondaryText>
              </Box>
              {hourlyReviewState.completed && (
                <SecondaryText>{t("pages.hourlyReview.tourFinished")}</SecondaryText>
              )}
              <Box mt={2} mx={4}>
                <TourStepper
                  steps={hourlyReviewTypes?.steps || []}
                  hourlyReviewState={hourlyReviewState}
                  loading={loading}
                  onComplete={handleComplete}
                />
              </Box>
            </Box>
          </Collapse>
        </Grid>
      </Grid>
      <NoteFab />
    </Container>
  );
};

export default HourlyReview;
