import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { IObjectiveItem, IObjectivesTypes } from "../../types";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  styled,
  Switch,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";
import { ActionButton, FlexBox, LightTypography, SecondaryText } from "../../styles";
import { getISOString, getObjectiveTypeDescription } from "../../helpers/functions";
import { useTranslation } from "react-i18next";
import ErrorIcon from "@mui/icons-material/Error";
import RightArrowIcon from "@mui/icons-material/ArrowForwardIos";
import LeftArrowIcon from "@mui/icons-material/ArrowBackIos";

const ArrowBtn = styled(Button)({
  padding: 0,
  minWidth: 0,
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const titleStyle: SxProps = {
  textAlign: "center",
  color: "primary",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

interface Props {
  objectivesList: IObjectiveItem[];
  objectiveTypes: IObjectivesTypes;
  data?: IObjectiveItem;
  open: boolean;
  onSubmit: (newData?: Partial<IObjectiveItem>, isDeleting?: boolean) => void;
}

export const ObjectiveFormModal = ({
  objectivesList,
  objectiveTypes,
  data,
  open,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();
  const [selectedObjType, setSelectedObjType] = React.useState("");
  const [selectedObjItem, setSelectedObjItem] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [completed, setCompleted] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const isEdit = Boolean(data);
  const diasableSubmit =
    Boolean(error) ||
    (selectedObjType === "OT" && description === "") ||
    (selectedObjType !== "OT" && selectedObjItem === "") ||
    selectedObjType === "" ||
    description === "";

  const defaultTimes = [50, 90, 120];

  React.useEffect(() => {
    if (data) {
      setSelectedObjType(data.objectiveTypeId);
      setSelectedObjItem(data.objectiveItemId);
      setDescription(data.objectiveDescription);
    }
  }, [data]);

  const handleSubmit = () => {
    if (checkDuplicate()) return null;
    const objType = objectiveTypes.objectiveType.find((o) => o.id === selectedObjType);
    const objItem = objectiveTypes.objectiveItem.find((o) => o.id === selectedObjItem);
    if (selectedObjType === "OT") {
      const newObjective: Partial<IObjectiveItem> = {
        objectiveTypeId: objType?.id,
        objectiveItemId: "OT1",
        objectiveDescription: description,
        objectiveAchieved: null,
        objectiveTypeDescription_es: objType?.description_es,
        objectiveTypeDescription_en: objType?.description_en,
        objectiveTypeDescription_fr: objType?.description_fr,
        objectiveTypeDescription_pt: objType?.description_pt,
        isAutomatic: objType?.isAutomatic,
        invokedService: objType?.invokedService,
        createdDate: !isEdit ? getISOString() : undefined,
      };
      onSubmit(newObjective);
    } else if (objType && objItem) {
      const newObjective: Partial<IObjectiveItem> = {
        objectiveTypeId: objType.id,
        objectiveItemId: objItem.id,
        objectiveDescription: description,
        objectiveAchieved: null,
        objectiveTypeDescription_es: objType.description_es,
        objectiveTypeDescription_en: objType.description_en,
        objectiveTypeDescription_fr: objType.description_fr,
        objectiveTypeDescription_pt: objType.description_pt,
        objectiveItemDescription_es: objItem.description_es,
        objectiveItemDescription_en: objItem.description_en,
        objectiveItemDescription_fr: objItem.description_fr,
        objectiveItemDescription_pt: objItem.description_pt,
        isAutomatic: objItem?.isAutomatic,
        invokedService: objItem?.invokedService,
        createdDate: !isEdit ? getISOString() : undefined,
      };
      onSubmit(newObjective);
    }
    handleReset();
  };

  const handleClose = () => {
    onSubmit();
    handleReset();
  };

  const checkDuplicate = () => {
    setError(undefined);
    let result = false;
    objectivesList.forEach((item) => {
      const { objectiveTypeId, objectiveItemId, objectiveDescription } = item;
      if (
        (selectedObjType === "OT" &&
          selectedObjType === objectiveTypeId &&
          description === objectiveDescription) ||
        (selectedObjType === objectiveTypeId &&
          selectedObjItem === objectiveItemId &&
          description === objectiveDescription)
      ) {
        result = true;
        setError(t("errors.objectives.duplicateObjective").toString());
        return;
      }
    });
    return result;
  };

  const handleTime = (value: number) => {
    let numValue = (parseInt(description) || 0) + value;
    setDescription(`${numValue < 0 ? 0 : numValue}`);
    setError(undefined);
  };

  const handleReset = () => {
    setSelectedObjType("");
    setSelectedObjItem("");
    setDescription("");
    setError(undefined);
  };

  /** RENDERS */

  const renderContent = () => {
    return (
      <>
        <DialogTitle sx={titleStyle}>
          <Box flexGrow={1} sx={isEdit ? { ml: 3 } : null}>
            {isEdit ? t("pages.objectives.editObjective") : t("pages.objectives.newObjective")}
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="description">
            {t("pages.objectives.newObjectiveDescription")}
          </DialogContentText>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12}>
              <TextField
                error={Boolean(error)}
                fullWidth
                id="department"
                select
                label={t("labels.department")}
                size="small"
                value={selectedObjType || ""}
                onChange={(e) => {
                  setSelectedObjType(e.target.value);
                  setSelectedObjItem("");
                  setDescription("");
                  setError(undefined);
                }}
                sx={{ mb: 2 }}
                disabled={isEdit}
              >
                {objectiveTypes.objectiveType.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {getObjectiveTypeDescription(item)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {selectedObjType !== "OT" && (
              <Grid item xs={12}>
                <TextField
                  error={Boolean(error)}
                  fullWidth
                  id="objective"
                  select
                  label={t("labels.objective")}
                  size="small"
                  value={selectedObjItem || ""}
                  onChange={(e) => {
                    setSelectedObjItem(e.target.value);
                    setDescription("");
                    setError(undefined);
                  }}
                  sx={{ mb: 2 }}
                  disabled={isEdit || selectedObjType === ""}
                >
                  {objectiveTypes.objectiveItem
                    .filter((item) => item.objectivesTypesId === selectedObjType)
                    .map((i, index) => (
                      <MenuItem value={i.id} key={index}>
                        {getObjectiveTypeDescription(i)}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            )}
            {["SG1", "PR4", "EC2"].includes(selectedObjItem) ? (
              <React.Fragment>
                <Grid item xs={12}>
                  <FlexBox sx={{ justifyContent: "space-between" }}>
                    <FlexBox>
                      <ArrowBtn size="small" onClick={() => handleTime(-5)}>
                        <LeftArrowIcon sx={{ mr: -2 }} />
                        <LeftArrowIcon />
                      </ArrowBtn>
                      <ArrowBtn size="small" onClick={() => handleTime(-1)}>
                        <LeftArrowIcon />
                      </ArrowBtn>
                      <TextField
                        error={Boolean(error)}
                        id="other-description"
                        label={t("labels.goal")}
                        value={description || ""}
                        onChange={(e) => {
                          setDescription(e.target.value);
                          setError(undefined);
                        }}
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <LightTypography>{t("labels.seconds").slice(0, 3)}</LightTypography>
                          ),
                        }}
                        disabled={isEdit}
                        size="small"
                        sx={{ width: 100 }}
                      />
                      <ArrowBtn size="small" onClick={() => handleTime(1)}>
                        <RightArrowIcon />
                      </ArrowBtn>
                      <ArrowBtn size="small" onClick={() => handleTime(5)}>
                        <RightArrowIcon />
                        <RightArrowIcon sx={{ ml: -2 }} />
                      </ArrowBtn>
                    </FlexBox>
                    <Box>
                      {defaultTimes.map((e) => (
                        <Button
                          key={e}
                          size="small"
                          variant="outlined"
                          sx={{ textTransform: "none", alignItems: "baseline", mx: 0.5 }}
                          onClick={() => {
                            setDescription(`${e}`);
                            setError(undefined);
                          }}
                        >
                          <Typography variant="h6">{e}</Typography>
                          <Typography variant="body1">{"s"}</Typography>
                        </Button>
                      ))}
                    </Box>
                  </FlexBox>
                </Grid>
              </React.Fragment>
            ) : (
              <Grid item xs={12}>
                <TextField
                  error={Boolean(error)}
                  fullWidth
                  id="other-description"
                  label={selectedObjType === "OT" ? t("labels.objective") : t("labels.goal")}
                  multiline
                  rows={3}
                  value={description || ""}
                  onChange={(e) => {
                    setDescription(e.target.value);
                    setError(undefined);
                  }}
                  disabled={isEdit}
                />
              </Grid>
            )}

            {isEdit && (
              <Grid item xs={12}>
                <FormControlLabel
                  sx={{ ml: 0, mt: 1 }}
                  control={
                    <Switch
                      color="primary"
                      onChange={(e, checked) => {
                        setCompleted(checked);
                      }}
                      checked={completed}
                    />
                  }
                  label={<SecondaryText>{t("labels.completed")}</SecondaryText>}
                  labelPlacement="start"
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </>
    );
  };

  const renderError = () => {
    if (error)
      return (
        <FlexBox ml={3}>
          <ErrorIcon color="error" sx={{ width: 15, height: 15 }} />
          <Typography variant="body2" color="error" ml={0.5}>
            {error}
          </Typography>
        </FlexBox>
      );
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-describedby="description"
      maxWidth={"sm"}
      keepMounted={false}
    >
      <Box p={1}>
        {renderContent()}
        {renderError()}
        <DialogActions sx={{ mx: 2 }}>
          <ActionButton
            variant="outlined"
            onClick={handleClose}
            sx={{ color: (theme) => theme.palette.text.primary }}
          >
            {t("labels.cancel")}
          </ActionButton>
          <ActionButton variant="contained" onClick={handleSubmit} disabled={diasableSubmit}>
            {t("labels.confirm")}
          </ActionButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ObjectiveFormModal;
