import {
  ActionType,
  ConsumeAlertAction,
  SetAlertAction,
  SetAlertPayload,
  ToggleSidebarAction,
  SetIsAppOnlineAction,
  ConsumeSnackbarAction,
  SetSnackbarAction,
  NavigateControllerPayload,
  SetNavigateControllerAction,
  SetSyncRequiredAction,
  SetSyncFailedAction,
  GetChangelogsReportDoneAction,
  GetChangelogsReportErrorAction,
  GetChangelogsReportPayload,
  GetChangelogsReportRequestedAction,
} from "./types";

export const setIsAppOnline = (result: { value: boolean }): SetIsAppOnlineAction => {
  return { type: ActionType.setIsAppOnline, payload: result };
};

export const toggleSidebar = (): ToggleSidebarAction => ({
  type: ActionType.toggleSidebar,
});

export const setNavigateController = (
  result: NavigateControllerPayload
): SetNavigateControllerAction => {
  return { type: ActionType.setNavigateController, payload: result };
};

export const setSyncRequired = (value: boolean): SetSyncRequiredAction => {
  return { type: ActionType.setSyncRequired, payload: { value } };
};

export const setSyncFailed = (value: boolean): SetSyncFailedAction => {
  return { type: ActionType.setSyncFailed, payload: { value } };
};

export const setAlert = (result: SetAlertPayload): SetAlertAction => {
  return { type: ActionType.setAlert, payload: result };
};

export const consumeAlert = (): ConsumeAlertAction => {
  return { type: ActionType.consumeAlert };
};

export const setSnackbar = (result: SetAlertPayload): SetSnackbarAction => {
  return { type: ActionType.setSnackbar, payload: result };
};

export const consumeSnackbar = (): ConsumeSnackbarAction => {
  return { type: ActionType.consumeSnackbar };
};

export const getChangelogsReportRequested = (): GetChangelogsReportRequestedAction => {
  return { type: ActionType.getChangelogsReportRequested };
};

export const getChangelogsReportDone = (result: GetChangelogsReportPayload): GetChangelogsReportDoneAction => {
  return { type: ActionType.getChangelogsReportDone, payload: result };
};

export const getChangelogsReportError = (): GetChangelogsReportErrorAction => {
  return { type: ActionType.getChangelogsReportError };
};
