import { useMemo } from "react";
import { ITimeFrameItem } from "../../types";
import { Box, SxProps, Typography } from "@mui/material";
import { CATEGORY_COLORS } from "../../helpers/constants";
import { addDays, isAfter, isBefore, isSameHour, subHours } from "date-fns";
import { useSelector } from "react-redux";
import { scheduleRangeSelector } from "../../redux/admin/schedules/selectors";

// function getMinutesPercentage(minutes: number, negative?: boolean) {
//   const value = (minutes * 99) / 59;
//   if (value > 0) return `${negative ? "-" : ""}${value}%`;
//   else return undefined;
// }

function getPercent(minutes: number) {
  if (!minutes || minutes === 0) return 0;
  else return (minutes * 99) / 59;
}

function getTimeRange(
  time: number,
  timeFrames: ITimeFrameItem[],
  category: string,
  businessDay: string,
  scheduleRange: number[]
) {
  let result = { range: "null", sameCategory: false, shortName: "", minutes: 0 };
  let currentDate = new Date(`${businessDay} ${time}:00`);
  const startHour = scheduleRange[0];
  const endHour = scheduleRange[scheduleRange.length - 1];

  if (endHour < startHour && time < startHour) currentDate = addDays(currentDate, 1);

  timeFrames.some((item) => {
    const startDate = new Date(item.startDay);
    const endDate = subHours(new Date(item.endDay), 1);

    result.sameCategory = item.location.category === category;
    result.shortName = item.location.shortName;

    if (isSameHour(currentDate, startDate) && isSameHour(currentDate, endDate)) {
      result.range = "start-end";
      return true;
    }

    if (isSameHour(currentDate, startDate)) {
      result.range = "start";
      result.minutes = getPercent(startDate.getMinutes());
      return true;
    }

    if (isSameHour(currentDate, endDate)) {
      result.range = "end";
      result.minutes = getPercent(endDate.getMinutes());
      return true;
    }

    if (isAfter(currentDate, startDate) && isBefore(currentDate, endDate)) {
      result.range = "middle";
      return true;
    }

    return false;
  });

  return result;
}

export const timeFrameBox: SxProps = {
  height: 24,
  my: 0.2
};

interface Props {
  index: number;
  time: number;
  timeFrames: ITimeFrameItem[];
  category: string;
  businessDay: string;
}

const TimeFrame = ({ index, time, timeFrames, category, businessDay }: Props) => {
  const scheduleRange = useSelector(scheduleRangeSelector);

  const timeRange = useMemo(() => {
    return getTimeRange(time, timeFrames, category, businessDay, scheduleRange);
  }, [time, timeFrames, category, businessDay, scheduleRange]);

  const lightColor = timeRange.sameCategory ? "" : "-LIGHT";
  const bgcolor = CATEGORY_COLORS[`${timeRange.shortName}${lightColor}`];

  switch (timeRange.range) {
    case "start-end":
      return (
        <Box sx={{ ...timeFrameBox, borderRadius: "50px 50px 50px 50px", bgcolor }}>
          <Typography color={"#FFF"} px={1} sx={{ zIndex: 100 }}>
            {timeRange.shortName}
          </Typography>
        </Box>
      );
    case "start":
      return (
        <Box
          sx={{
            ...timeFrameBox,
            borderRadius: "50px 0px 0px 50px",
            bgcolor,
            ml: `${timeRange.minutes}%`,
          }}
        >
          <Typography color={"#FFF"} px={1} sx={{ zIndex: 100 }}>
            {timeRange.shortName}
          </Typography>
        </Box>
      );
    case "end":
      return (
        <Box
          sx={{
            ...timeFrameBox,
            borderRadius: "0px 50px 50px 0px",
            bgcolor,
            mr: `-${timeRange.minutes}%`,
          }}
        />
      );
    case "middle":
      return <Box sx={{ ...timeFrameBox, bgcolor }} />;
    default:
      return <Box sx={{ ...timeFrameBox }} />;
  }
};

export default TimeFrame;
