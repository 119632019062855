import React from "react";
import { Box, Chip, SxProps, Theme, Typography } from "@mui/material";
import { IObjectiveItem } from "../../types";
import { FlexBox, SecondaryText, StyledBadge } from "../../styles";
import { getObjectiveDescription } from "../../helpers/functions";
import CheckIcon from "@mui/icons-material/SentimentVerySatisfied";
import NonCheckIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import NoDataIcon from "@mui/icons-material/SentimentNeutral";
import TimeIcon from "@mui/icons-material/Timer";
import CommentsIcon from "@mui/icons-material/ChatBubbleOutline";
import { useSelector } from "react-redux";
import {
  sosDtReportSelector,
  sosFcReportSelector,
  sosMfyReportSelector,
} from "../../redux/activities/objectives/selectors";
import { useTranslation } from "react-i18next";

const cardStyle: SxProps = {
  width: "100%",
  height: "100%",
  borderRadius: 3,
};

const contentStyle: SxProps = {
  height: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const disabledContent: SxProps = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  opacity: 0.7,
};

export const objTypeChip: SxProps<Theme> = (theme) => ({
  bgcolor: "secondary.light",
  fontWeight: 500,
  px: 0.5,
  color: theme.palette.mode === "dark" ? "black" : undefined,
});

const infoColor: SxProps = {
  opacity: 0.5,
};

const iconSize: SxProps = {
  width: 60,
  height: 60,
};

interface IObjState {
  reached: boolean;
  current: number | null;
}

interface Props {
  data: IObjectiveItem;
  selected?: number;
  loading?: boolean;
  onClick: (data: IObjectiveItem | null) => void;
}

const AutomaticObjectiveCard = ({ data, loading, onClick }: Props) => {
  const { t } = useTranslation();
  const sosDtReport = useSelector(sosDtReportSelector);
  const sosFcReport = useSelector(sosFcReportSelector);
  const sosMfyReport = useSelector(sosMfyReportSelector);
  const [objState, setObjState] = React.useState<IObjState>({ current: null, reached: false });

  const handleChecked = (goal: number, current?: number | null) => {
    if (!isNaN(goal) && current && current <= goal)
      setObjState({ current: current, reached: true });
    else setObjState({ current: current || null, reached: false });
  };

  const renderIcon = () => {
    if (!objState.current) return <NoDataIcon sx={{ ...iconSize, color: "text.secondary" }} />;

    if (objState.reached) return <CheckIcon sx={iconSize} color="success" />;
    else return <NonCheckIcon sx={iconSize} color="error" />;
  };

  React.useEffect(() => {
    // if (data.objectiveAchieved !== null)
    //   setObjState({
    //     current: parseInt(data.objectiveDescription),
    //     reached: data.objectiveAchieved,
    //   });
    // else
      switch (data.invokedService) {
        case "SOS_DT":
          handleChecked(parseInt(data.objectiveDescription), sosDtReport?.oepe);
          break;
        case "SOS_FC":
          handleChecked(parseInt(data.objectiveDescription), sosFcReport?.r2p);
          break;
        case "SOS_MFY":
          handleChecked(parseInt(data.objectiveDescription), sosMfyReport?.tts);
          break;

        default:
          setObjState({ current: null, reached: false });
          break;
      }
  }, [data, sosDtReport, sosFcReport, sosMfyReport]);

  return (
    <Box sx={cardStyle}>
      <Box sx={loading ? disabledContent : contentStyle}>
        <Box sx={{ width: "100%", cursor: "pointer" }} onClick={() => onClick(data)}>
          <FlexBox>
            <Chip label={getObjectiveDescription(data, "type")} size="small" sx={objTypeChip} />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <StyledBadge badgeContent="" color="secondary" sx={{ ml: 2 }}>
                <CommentsIcon sx={{ ...infoColor, height: 21, width: 21 }} />
              </StyledBadge>
              <Typography ml={1} sx={infoColor}>
                {data?.comments?.length || 0}
              </Typography>
            </Box>
          </FlexBox>
          <Typography variant="h6" sx={{ fontWeight: 400, mt: 0.5 }}>
            {getObjectiveDescription(data, "item")}
          </Typography>
          <SecondaryText variant="body1">{`${t("labels.goal")}: ${
            data.objectiveDescription
          }s`}</SecondaryText>
        </Box>
        <Box textAlign={"center"}>
          {renderIcon()}
          <FlexBox sx={{ justifyContent: "center" }}>
            <TimeIcon sx={{ opacity: 0.7, width: 20, height: 20, mr: 0.5 }} />
            <SecondaryText variant="body2" sx={{ mr: 0.5 }}>
              {objState.current ? `${objState.current}s` : "---"}
            </SecondaryText>
          </FlexBox>
        </Box>
      </Box>
    </Box>
  );
};

export default AutomaticObjectiveCard;
