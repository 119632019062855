import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function formattedTime(value: Date) {
  return format(value, "HH:mm");
}

interface Props {
  open: boolean;
  handleSubmit: (value?: string) => void;
}

const TimePickerModal = ({ open, handleSubmit }: Props) => {
  const { t } = useTranslation();

  const handleClose = () => {
    handleSubmit();
  };

  const onAccept = (value: Date | null) => {
    value && handleSubmit(formattedTime(value));
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted={false}
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticTimePicker
            orientation="portrait"
            ampm={false}
            onClose={handleClose}
            onAccept={onAccept}
            localeText={{
              cancelButtonLabel: t("labels.cancel").toString(),
              okButtonLabel: t("labels.save").toString(),
              toolbarTitle: t("pages.schedules.selectTime").toString(),
            }}
          />
        </LocalizationProvider>
      </DialogContent>
    </Dialog>
  );
};

export default TimePickerModal;
