import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { Box, Divider, Paper, Theme, Typography, useMediaQuery } from "@mui/material";
import { SEGMENTS_ID, SERIES_COLORS } from "../helpers/constants";
import { IChartSerie, ICurrentSegmentsMapper, ISegmentItem } from "../types";
import SegmentStatCard from "./cards/SegmentStatCard";
import SegmentTotalStatCard from "./cards/SegmentTotalStatCard";
import LineChart from "./charts/LineChart";
import { numberFormatter } from "../helpers/functions";
import { useEffect, useState } from "react";
import UndefinedGcCard from "./cards/UndefinedGcCard";
import { useSelector } from "react-redux";
import { filtersSelector } from "../redux/admin/masterdata/selectors";

export interface ISeverity {
  value: number;
  negative: boolean;
}

function getSeverity(real?: number, proy?: number) {
  if (real && proy) {
    const percentage = (real * 100) / proy;
    if (percentage < 100) return { value: 100 - percentage, negative: true };
    else return { value: percentage - 100, negative: false };
  }
  return { value: 0, negative: false };
}

function getCurrentHour() {
  const hour = new Date().getHours();
  const from = numberFormatter(hour - 1, 2);
  const to = numberFormatter(hour, 2);

  return `${from}:00 - ${to}:00`;
}

interface Props {
  categories: string[];
  currentSegments: ICurrentSegmentsMapper;
  dailyTotals: ISegmentItem | null;
  series: IChartSerie[];
}

const DailyTotalsDashboard = ({ categories, currentSegments, dailyTotals, series }: Props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const filters = useSelector(filtersSelector);
  const [currentHour, setCurrentHour] = useState(getCurrentHour());

  useEffect(() => {
    setCurrentHour(getCurrentHour());
  }, [currentSegments]);

  return (
    <Grid container spacing={3} direction={isMobile ? "column-reverse" : "row"}>
      <Grid item lg={8.5} xs={12}>
        <Box component={Paper}>
          <Grid container>
            <Grid item lg={3.2} md={3.5}>
              <SegmentTotalStatCard
                title={t("labels.store")}
                value={filters.store?.storeAcronym}
                type="store"
              />
              <Divider flexItem sx={{ mx: 2 }} />
              <SegmentTotalStatCard
                title={t("labels.gcReal")}
                value={dailyTotals?.gcReal}
                severity={getSeverity(dailyTotals?.gcReal, dailyTotals?.gcProy)}
                type="gc"
              />
              <div style={{ margin: "5%" }} />
              <SegmentTotalStatCard
                title={t("labels.ipReal")}
                value={dailyTotals?.ipReal}
                severity={getSeverity(dailyTotals?.ipReal, dailyTotals?.ipProy)}
                type="ip"
              />
            </Grid>
            <Divider flexItem orientation="vertical" sx={{ my: 2 }} />
            <Grid item pt={2} px={2} lg={8.7} md={8.4}>
              <LineChart
                title={t("labels.dailyTotalsTitle").toString()}
                series={series}
                categories={categories}
                disableFuture
                height={327}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item lg={3.5} xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box component={Paper} p={0.5}>
              <Typography sx={{ textAlign: "center", fontWeight: 500 }}>
                {t("labels.lastHourTotals", { value: currentHour })}
              </Typography>
            </Box>
          </Grid>
          {SEGMENTS_ID.map(
            (item) =>
              item !== "TOTALS" && (
                <Grid key={item} item xs={6}>
                  <SegmentStatCard
                    color={SERIES_COLORS[item]}
                    data={currentSegments[item]}
                    segment={item}
                  />
                </Grid>
              )
          )}
          <Grid item xs={6}>
            <UndefinedGcCard data={currentSegments["OTHERS"]} segment={"OTHERS"} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DailyTotalsDashboard;
