import {
  Badge,
  BadgeProps,
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  SxProps,
  Theme,
  Typography,
  styled,
} from "@mui/material";
import { grey } from "@mui/material/colors";

export const selectedStyle: SxProps<Theme> = (theme) => ({
  // border: 2,
  borderColor: theme.palette.mode === "dark" ? grey[900] : "primary.light",
  bgcolor: theme.palette.mode === "dark" ? "inherit" : "primary.light",
});

export const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 20,
  textTransform: "none",
}));

export const Title = styled(Typography)(({ theme }) => ({
  opacity: 0.9,
}));

export const SecondaryText = styled(Typography)(({ theme }) => ({
  opacity: 0.8,
}));

export const BoldTypography = styled(Typography)({
  fontWeight: 600,
});

export const LightTypography = styled(Typography)({
  opacity: 0.6,
});

interface ActionButtonProps extends ButtonProps {
  children: React.ReactNode;
  loading?: boolean;
}
export const ActionButton = ({ children, loading, ...props }: ActionButtonProps) => {
  const buttonProgress: SxProps = {
    position: "absolute",
    color: grey[800],
    opacity: 0.8,
  };
  return (
    <StyledButton size="small" {...props}>
      {loading && <CircularProgress size={20} sx={buttonProgress} />}
      {children}
    </StyledButton>
  );
};

export const FlexBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

export const areaListContainer: SxProps = {
  overflow: "auto",
  height: "calc(100vh - 88px)",
  mt: 2,
  paddingTop: "0 !important",
  pr: 1,
};

export const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -5,
    top: -2,
    border: `2px solid ${theme.palette.background.paper}`,
    transform: "scale(0.7)",
  },
}));
