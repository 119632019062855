import React from "react";
import { TextField, Box, Autocomplete } from "@mui/material";
import { ICountry } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import {
  filteredOptionsSelector,
  filtersSelector,
  masterdataSelector,
} from "../../redux/admin/masterdata/selectors";
import { setFilteredOptions, setFilters } from "../../redux/admin/masterdata/actions";
import { useTranslation } from "react-i18next";

interface Props {
  options: ICountry[];
}

const CountrySelector = ({ options }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filters = useSelector(filtersSelector);
  const masterData = useSelector(masterdataSelector);
  const filteredOptions = useSelector(filteredOptionsSelector);
  const { stores } = masterData;
  const disabled = !Boolean(filters.division);

  const handleChange = (value: ICountry) => {
    const filteredStores = stores.filter((s) => s.country === value.label);
    dispatch(setFilters({ filters: { ...filters, country: value, store: undefined } }));
    dispatch(
      setFilteredOptions({
        options: { ...filteredOptions, stores: filteredStores },
      })
    );
  };

  return (
    <Box mb={1} mx={2}>
      <Autocomplete
        disablePortal
        id="division-selector"
        value={filters.country || null}
        options={options}
        getOptionLabel={(option) => t(`filters.${option.label}`)}
        renderInput={(params) => (
          <TextField variant="standard" {...params} label={t("labels.country")} />
        )}
        onChange={(e, value) => value && handleChange(value)}
        disabled={disabled}
        sx={{ width: 150 }}
        size="small"
      />
    </Box>
  );
};

export default CountrySelector;
