import React from "react";
import { Avatar, Box, CircularProgress, Divider, Paper, SxProps, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import NoteCard from "./cards/NoteCard";
import { INoteItem } from "../types";
import { FlexBox } from "../styles";
import { Droppable } from "react-beautiful-dnd";

const loader: SxProps = {
  position: "absolute",
  left: "50%",
  opacity: 0.8,
};
interface Props {
  notes: INoteItem[];
  title: string;
  state: "active" | "pending" | "done";
  loading?: boolean;
  onClick: (note?: INoteItem) => void;
}

const NoteList = ({ notes, title, state, loading, onClick }: Props) => {
  const getStateColor = () => {
    switch (state) {
      case "pending":
        return "secondary.main";
      case "active":
        return "info.light";
      case "done":
        return "success.light";
      default:
        return undefined;
    }
  };

  return (
    <Box component={Paper} p={2} pt={0}>
      <FlexBox pt={1} sx={{ position: "relative" }}>
        <Typography sx={{ fontWeight: 500 }}>{title}</Typography>
        <Avatar sx={{ width: 20, height: 20, ml: 1 }}>
          <Typography variant="caption" sx={{ fontWeight: 500 }}>
            {notes.length}
          </Typography>
        </Avatar>
        {loading && <CircularProgress size={18} sx={loader} />}
      </FlexBox>
      {state && <Divider sx={{ bgcolor: getStateColor(), height: 3, mt: 1, mb: 2 }} />}

      <Droppable droppableId={`${state}`}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{ height: "calc(100vh - 234px)", overflow: "auto" }}
          >
            <Grid container spacing={1}>
              {notes.map((note, index) => (
                <Grid item xs={12} my={1} mx={2} key={`note-list-${note.id}`}>
                  <NoteCard data={note} state={state} index={index} onClick={onClick} />
                </Grid>
              ))}

              {provided.placeholder}
            </Grid>
          </div>
        )}
      </Droppable>
    </Box>
  );
};

export default NoteList;
