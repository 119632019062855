import React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { SxProps, Theme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { grey } from "@mui/material/colors";
import { ActionButton, FlexBox, SecondaryText } from "../../styles";
import { IShiftSchedules, IScheduleItem, AlertCode } from "../../types";
import {
  AM_RUSH_HOURS,
  PM_RUSH_HOURS,
  WEEK_DAYS,
} from "../../helpers/constants";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  loadingSelector,
  schedulesDataSelector,
} from "../../redux/admin/schedules/selectors";
import { setSchedulesRequested } from "../../redux/admin/schedules/actions";
import { setNavigateController, setSnackbar } from "../../redux/app/actions";
import TimePickerModal from "../../components/modals/TimePickerModal";
import CopyIcon from "@mui/icons-material/ContentCopy";
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { getScheduleDescription } from "../../helpers/functions";
import ConfirmModal from "../../components/modals/ConfirmModal";
import {
  isAppOnlineSelector,
  navigateSelector,
} from "../../redux/app/selectors";
import RushHoursSelector from "../../components/ScheduleButtons";

interface TimeIndex {
  index: number;
  time: string;
}

interface TimeError {
  [key: number]: { start: boolean; end: boolean };
}

const disabledStyle: SxProps<Theme> = {
  bgcolor: (theme) => (theme.palette.mode === "light" ? grey[200] : grey[800]),
  opacity: 0.5,
};

const enabledStyle: SxProps = {
  "&:hover": {
    cursor: "pointer",
  },
};

const readOnlyStyle: SxProps = {
  "&:hover": {
    cursor: "not-allowed",
  },
};

const defaultRH: IScheduleItem = {
  weekday: "",
  shiftTypeId: "RH",
  shiftTypeDescription_es: "",
  shiftTypeDescription_en: "",
  shiftTypeDescription_pt: "",
  shiftTypeDescription_fr: "",
  shiftStart: "",
  shiftEnd: "",
  enabled: false,
  rushHours: [],
};

function defaultGenerator(
  data: IShiftSchedules,
  weekday: string
): IScheduleItem[] {
  if (data["monday"]) {
    const newData = data["monday"].map((item) => ({
      ...item,
      shiftStart: "",
      shiftEnd: "",
      enabled: item.enabled,
      weekday,
      rushHours: item.rushHours ? [...item.rushHours] : [],
    }));
    return newData;
  } else return [];
}

function getVariant(day: string, dayIndex: string) {
  if (day === dayIndex) return "contained";
  return "outlined";
}

function getColor(day: string, dayIndex: string) {
  if (day === dayIndex) return "primary";
  return "inherit";
}

function isComplete(schedule: IShiftSchedules) {
  if (Object.keys(schedule).length < 7) {
    return false;
  }
  let result = true;
  Object.keys(schedule).forEach((key) => {
    schedule[key].forEach((e) => {
      if (e.shiftTypeId === "RH" && e.enabled && e.rushHours.length === 0) {
        result = false;
        return;
      } else if (
        e.shiftTypeId !== "RH" &&
        e.enabled &&
        (e.shiftStart === "" || e.shiftEnd === "")
      ) {
        result = false;
        return;
      }
    });
  });
  return result;
}

function isEnabled(item: IScheduleItem) {
  return item.enabled && (item.shiftStart !== "" || item.shiftEnd !== "");
}

function parseTimes(data: IScheduleItem) {
  return {
    start: parseInt(data.shiftStart.replace(":", "")),
    end: parseInt(data.shiftEnd.replace(":", "")),
  };
}

function newScheduleItemInstance(
  data: IScheduleItem[],
  weekday: string,
  rushHours?: number[]
): IScheduleItem[] {
  const newData: IScheduleItem[] = [];
  let haveRH = false;

  data.forEach((e) => {
    if (e.shiftTypeId === "RH") {
      haveRH = true;
      const newRushHours = e.rushHours ? [...e.rushHours] : [];
      newData.push({
        ...e,
        rushHours: rushHours ? [...rushHours] : newRushHours,
      });
    } else newData.push({ ...e });
  });

  if (!haveRH) {
    newData.push({ ...defaultRH, weekday, rushHours: rushHours || [] });
  }

  return newData;
}

function newScheduleInstance(data: IShiftSchedules): IShiftSchedules {
  let result: IShiftSchedules = {};
  Object.keys(data).forEach((key) => {
    const weekday = data[key] ? data[key][0]?.weekday || "monday" : "monday";
    const newItems = newScheduleItemInstance(data[key], weekday);
    result[key] = newItems;
  });

  return result;
}

function getRushHours(data: number[]) {
  let am: number[] = [];
  let pm: number[] = [];
  data.forEach((hour) => {
    if (hour >= 1 && hour <= 12) am.push(hour);
    else pm.push(hour);
  });
  return { am, pm };
}

const Schedules = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAppOnline = useSelector(isAppOnlineSelector);
  const dataSelector = useSelector(schedulesDataSelector);
  const navigateController = useSelector(navigateSelector);
  const loading = useSelector(loadingSelector);
  const initialData: IScheduleItem[] = dataSelector["monday"]?.map((item) => ({
    ...item,
    shiftStart: "",
    shiftEnd: "",
    weekday: "monday",
  }));

  const [schedules, setSchedules] = React.useState<IShiftSchedules>({});
  const [selected, setSelected] = React.useState<IScheduleItem[]>(initialData);
  const [dayIndex, setDayIndex] = React.useState<string>("monday");
  const [timeIndex, setTimeIndex] = React.useState<TimeIndex>();
  const [error, setError] = React.useState<TimeError>({});
  const [errorDetails, setErrorDetails] = React.useState<string[]>([]);
  const [openTime, setOpenTime] = React.useState(false);
  const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
  const [saveDay, setSaveDay] = React.useState(false);
  const hasError = Object.keys(error).length > 0;

  const [amRushHours, setAmRushHours] = React.useState<number[]>([]);
  const [pmRushHours, setPmRushHours] = React.useState<number[]>([]);
  const [rushHoursIndex, setRushHoursIndex] = React.useState(0);

  React.useEffect(() => {
    if (dataSelector[dayIndex] && dataSelector[dayIndex][0]?.weekday) {
      const newState = newScheduleItemInstance(
        dataSelector[dayIndex],
        dayIndex
      );
      const rhIndex = newState.findIndex((e) => e.shiftTypeId === "RH");
      const { am, pm } = getRushHours(newState[rhIndex]?.rushHours || []);
      setAmRushHours(am);
      setPmRushHours(pm);
      setRushHoursIndex(rhIndex);

      setSelected(newState);
      setSchedules(newScheduleInstance(dataSelector));
    }
    // eslint-disable-next-line
  }, [dataSelector]);

  const handleSubmit = () => {
    let newSchedule = newScheduleInstance(schedules);
    newSchedule[dayIndex] = [...selected];

    setSchedules(newSchedule);
    if (isComplete(newSchedule)) {
      dispatch(setSchedulesRequested(newSchedule));
      dispatch(
        setNavigateController({ navigateController: { hasChanges: false } })
      );
    } else {
      dispatch(
        setSnackbar({
          alert: { code: AlertCode.INCOMPLETE_SCHEDULE, severity: "error" },
        })
      );
    }
  };

  React.useEffect(() => {
    if (navigateController.path && navigateController.saveChanges === true) {
      handleSubmit();
      dispatch(
        setNavigateController({
          navigateController: {
            ...navigateController,
            hasChanges: false,
            saveChanges: false,
          },
        })
      );
    }
    // eslint-disable-next-line
  }, [navigateController]);

  const handleDayClick = (day: string) => {
    let newSchedule = newScheduleInstance(schedules);
    newSchedule[dayIndex] = [...selected];
    if (newSchedule[day]) {
      const rhIndex = newSchedule[day].findIndex((e) => e.shiftTypeId === "RH");
      if (rhIndex !== -1) {
        const { am, pm } = getRushHours(
          newSchedule[day][rhIndex]?.rushHours || []
        );
        setAmRushHours(am);
        setPmRushHours(pm);
        setRushHoursIndex(rhIndex);
      } else {
        newSchedule[day].push({ ...defaultRH, weekday: day });
        setAmRushHours([]);
        setPmRushHours([]);
        setRushHoursIndex(4);
      }
      setSelected(newSchedule[day]);
    } else {
      setSelected(defaultGenerator(dataSelector, day));
      setAmRushHours([]);
      setPmRushHours([]);
      setRushHoursIndex(0);
    }

    if (saveDay) {
      setSchedules(newSchedule);
      setSaveDay(false);
      dispatch(
        setNavigateController({ navigateController: { hasChanges: true } })
      );
    }
    setDayIndex(day);
  };

  const handleAutocomplete = (confirm?: boolean) => {
    if (confirm) {
      const newSchedule = { ...schedules };
      WEEK_DAYS.forEach((day) => {
        const newSelected: IScheduleItem[] = selected.map((e) => ({
          ...e,
          weekday: day,
        }));
        newSchedule[day] = [...newSelected];
      });
      setSchedules(newSchedule);
      !saveDay && setSaveDay(true);
      dispatch(
        setNavigateController({ navigateController: { hasChanges: true } })
      );
    }
    setOpenAutocomplete(false);
  };

  const handleSwitch = (
    value: boolean,
    index: number,
    isRushHours?: boolean
  ) => {
    let newState = newScheduleItemInstance(selected, dayIndex);
    newState[index] = { ...newState[index], enabled: value };

    if (isRushHours) {
      newState.forEach((e) => {
        if (e.shiftTypeId !== "E" || !value) e.enabled = value;
      });
    } else {
      let anyEnabled = false;
      newState.forEach((e, i) => {
        if (e.shiftTypeId !== "RH" && i === index && value && !anyEnabled) {
          anyEnabled = true;
        } else if (e.shiftTypeId !== "RH" && e.enabled && !anyEnabled) {
          anyEnabled = true;
        }
      });
      newState[rushHoursIndex].enabled = anyEnabled;
    }

    setSelected(newState);
    validateTimes(newState);
    !saveDay && setSaveDay(true);
    !navigateController.hasChanges &&
      dispatch(
        setNavigateController({ navigateController: { hasChanges: true } })
      );
  };

  const handleInputClick = (index: number, time: string) => {
    setTimeIndex({ index, time });
    setOpenTime(true);
  };

  const validateTimes = (data: IScheduleItem[]) => {
    let newError: TimeError = {};
    let newErrorDetails: string[] = [];
    const shifts = [0, 1, 2, 3];
    /**Current day */
    shifts.forEach((shift) => {
      if (isEnabled(data[shift])) {
        const time = getScheduleDescription(data[shift]);
        const range = parseTimes(data[shift]);
        /** Validate current range */
        if ([0, 1].includes(shift) && range.start > range.end) {
          newError[shift] = { start: true, end: true };
          newErrorDetails.push(t("errors.schedule.shiftRange", { time }));
        }
        /** Validate previous range */
        if ([1, 2, 3].includes(shift))
          for (let preShift = shift - 1; preShift >= 0; preShift--) {
            if (isEnabled(data[preShift])) {
              const preRange = parseTimes(data[preShift]);
              if (range.start < preRange.end) {
                newError[preShift] = { ...newError[preShift], end: true };
                newError[shift] = { ...newError[shift], start: true };
                newErrorDetails.push(
                  t("errors.schedule.preShiftRange", { time })
                );
              }
              return;
            }
          }
      }
    });
    setError(newError);
    setErrorDetails(newErrorDetails);
  };

  const handleTime = (value?: string) => {
    if (value && timeIndex) {
      const { index, time } = timeIndex;
      let newState = newScheduleItemInstance(selected, dayIndex);
      if (time === "start")
        newState[index] = { ...newState[index], shiftStart: value };
      if (time === "end")
        newState[index] = { ...newState[index], shiftEnd: value };
      setSelected(newState);
      validateTimes(newState);
      !saveDay && setSaveDay(true);
      dispatch(
        setNavigateController({ navigateController: { hasChanges: true } })
      );
    }
    setOpenTime(false);
  };

  const handleDelete = (index: number) => {
    let newState = newScheduleItemInstance(selected, dayIndex); // [...selected];
    newState[index] = { ...newState[index], shiftStart: "", shiftEnd: "" };
    setSelected(newState);
    validateTimes(newState);
    !saveDay && setSaveDay(true);
    dispatch(
      setNavigateController({ navigateController: { hasChanges: true } })
    );
  };

  const handleRushHours = (value: number[], time: string) => {
    if (time === "AM") {
      setAmRushHours(value);
      const newStateByAm = newScheduleItemInstance(selected, dayIndex, [
        ...value,
        ...pmRushHours,
      ]);
      setSelected(newStateByAm);
    } else if (time === "PM") {
      setPmRushHours(value);
      const newStateByPm = newScheduleItemInstance(selected, dayIndex, [
        ...amRushHours,
        ...value,
      ]);
      setSelected(newStateByPm);
    }
    !saveDay && setSaveDay(true);
    !navigateController.hasChanges &&
      dispatch(
        setNavigateController({ navigateController: { hasChanges: true } })
      );
  };

  return (
    <Container>
      <Box p={2} mb={3} textAlign={"center"}>
        <Typography variant="h4">{t("pages.schedules.title")}</Typography>
        <SecondaryText variant="body1">
          {t("pages.schedules.subtitle")}
        </SecondaryText>
      </Box>
      <Box component={Paper} px={4} py={2.5}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12}>
            <FlexBox sx={{ justifyContent: "space-between" }}>
              <Typography variant="h5">
                {t("pages.schedules.description")}
              </Typography>
              <ActionButton
                variant="contained"
                sx={{ minWidth: 120 }}
                onClick={handleSubmit}
                loading={loading}
                disabled={hasError || loading || !isAppOnline}
                startIcon={<SaveIcon />}
              >
                {t("labels.save")}
              </ActionButton>
            </FlexBox>
            <Divider sx={{ my: 1.5 }} />
          </Grid>
          <Grid item xs={10.5}>
            <ButtonGroup fullWidth>
              {WEEK_DAYS.map((day, index) => (
                <Button
                  variant={getVariant(day, dayIndex)}
                  color={getColor(day, dayIndex)}
                  onClick={() => handleDayClick(day)}
                  key={index}
                  size="small"
                  disabled={hasError}
                  sx={{ borderColor: grey[400] }}
                >
                  {t(`labels.${day}`)}
                </Button>
              ))}
            </ButtonGroup>
          </Grid>
          <Grid item xs={1.5}>
            <Tooltip title={t("pages.schedules.autocomplete")}>
              <IconButton
                sx={{ ml: 1.5, boxShadow: 3 }}
                onClick={() => setOpenAutocomplete(true)}
                disabled={hasError || !isAppOnline}
                size="small"
              >
                <CopyIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          {selected.map(
            (i, index) =>
              i.shiftTypeId !== "RH" && (
                <React.Fragment key={index}>
                  <Grid item xs={7.5}>
                    <TextField
                      fullWidth
                      value={getScheduleDescription(i)}
                      variant="outlined"
                      size="small"
                      inputProps={{
                        readOnly: true,
                        style: {
                          fontWeight: 400,
                          cursor: "default",
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              sx={{ opacity: 0.7, padding: 0.5 }}
                              onClick={() => handleDelete(index)}
                            >
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      disabled={!i.enabled}
                      sx={!i.enabled ? disabledStyle : readOnlyStyle}
                    />
                  </Grid>
                  <Grid item xs={1.5} textAlign="center">
                    <TextField
                      error={Boolean(error[index]?.start)}
                      fullWidth
                      value={i.shiftStart}
                      variant="outlined"
                      size="small"
                      inputProps={{
                        style: { textAlign: "center", cursor: "pointer" },
                      }}
                      disabled={!i.enabled}
                      sx={!i.enabled ? disabledStyle : enabledStyle}
                      placeholder="--:--"
                      autoComplete="off"
                      onClick={() =>
                        i.enabled && handleInputClick(index, "start")
                      }
                    />
                  </Grid>
                  <Grid item xs={1.5} textAlign="center">
                    <TextField
                      error={Boolean(error[index]?.end)}
                      fullWidth
                      value={i.shiftEnd}
                      variant="outlined"
                      size="small"
                      inputProps={{
                        style: { textAlign: "center", cursor: "pointer" },
                      }}
                      disabled={!i.enabled}
                      sx={!i.enabled ? disabledStyle : enabledStyle}
                      placeholder="--:--"
                      autoComplete="off"
                      onClick={() =>
                        i.enabled && handleInputClick(index, "end")
                      }
                    />
                  </Grid>
                  <Grid item xs={1.5}>
                    <Switch
                      checked={i.enabled}
                      disabled={!isAppOnline}
                      onChange={(e, checked) => handleSwitch(checked, index)}
                    />
                  </Grid>
                </React.Fragment>
              )
          )}
          <Grid item xs={12} sx={{ display: hasError ? "inherit" : "none" }}>
            <List>
              {errorDetails.map((e, index) => (
                <ListItem sx={{ py: 0 }} key={index}>
                  <ListItemIcon sx={{ minWidth: 22 }}>
                    <ErrorIcon color="error" sx={{ width: 16, height: 16 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="caption" color="error">
                        {e}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems={"center"} mt={2} pr={9}>
          <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
            <FlexBox>
              <Typography variant="h5">{"Peak Hours"}</Typography>
              <SecondaryText variant="body1" sx={{ mx: 1, mt: 0.5 }}>
                {"(Rush Hours)"}
              </SecondaryText>
              <Switch
                checked={selected[rushHoursIndex]?.enabled || false}
                disabled={!isAppOnline}
                onChange={(e, checked) =>
                  handleSwitch(checked, rushHoursIndex, true)
                }
                sx={{ mt: 0.5 }}
              />
            </FlexBox>
          </Grid>

          <Grid item xs={12} mt={1}>
            <RushHoursSelector
              values={AM_RUSH_HOURS}
              selected={amRushHours}
              time={"AM"}
              disabled={
                !selected[rushHoursIndex]?.enabled || !isAppOnline || false
              }
              onSelect={handleRushHours}
            />
          </Grid>
          <Grid item xs={12}>
            <RushHoursSelector
              values={PM_RUSH_HOURS}
              selected={pmRushHours}
              time={"PM"}
              disabled={
                !selected[rushHoursIndex]?.enabled || !isAppOnline || false
              }
              onSelect={handleRushHours}
            />
          </Grid>
        </Grid>
        {/* <SettingsChangesMsg show={getSchedulesCompleted(schedules)} /> */}
      </Box>
      <TimePickerModal open={openTime} handleSubmit={handleTime} />
      <ConfirmModal
        open={openAutocomplete}
        onSubmit={handleAutocomplete}
        title={t("messages.autocompleteSchedule.title")}
        content={t("messages.autocompleteSchedule.content")}
      />
    </Container>
  );
};

export default Schedules;
