import React, { useState } from "react";
import ChangelogAccordion from "./ChangelogAccordion";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { changelogsReportSelector } from "../../redux/app/selectors";
import { useTranslation } from "react-i18next";

const Changelog = () => {
  const { t } = useTranslation();
  const changelogsReport = useSelector(changelogsReportSelector);
  const [selected, setSelected] = useState<string | null>(changelogsReport[0]?.id || null);

  const handleToggle = (id: string, expanded: boolean) => {
    if (expanded) setSelected(id);
    else setSelected(null);
  };

  return (
    <Box>
      {changelogsReport.length === 0 && (
        <Typography variant="h6">{t("pages.changelogs.noData")}</Typography>
      )}
      {changelogsReport.map((item) => (
        <ChangelogAccordion item={item} isExpanded={selected === item.id} toggle={handleToggle} />
      ))}
    </Box>
  );
};

export default Changelog;
