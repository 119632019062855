import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { IActivityItem } from "../types";
import CheckItem from "./CheckItem";
import SaveIcon from "@mui/icons-material/Save";
import CheckAllIcon from "@mui/icons-material/LibraryAddCheck";
import { ActionButton } from "../styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  checklistsStateSelector,
  loadingChecklistSelector,
} from "../redux/activities/checklists/selectors";
import ProgressCard, { IProgress } from "./cards/ProgressCard";
import { isAppOnlineSelector, navigateSelector } from "../redux/app/selectors";
import { setNavigateController } from "../redux/app/actions";
import NoteFab from "./NoteFab";

const calcProgress = (checklist: IActivityItem[]): IProgress => {
  let progress: IProgress = {
    total: checklist?.length || 0,
    pending: 0,
    finished: 0,
  };
  checklist.forEach((e) => {
    if (e.checked) {
      progress.finished++;
    } else {
      progress.pending++;
    }
  });
  return progress;
};
interface Props {
  title: string;
  data: IActivityItem[];
  showProgress?: boolean;
  hideAddNote?: boolean;
  isCustom?: boolean;
  getProgress?: (progress: IProgress, data?: IActivityItem[]) => void;
  onSubmit: (data: IActivityItem[]) => void;
}

const CheckList = ({
  title,
  data,
  showProgress,
  hideAddNote,
  isCustom,
  onSubmit,
  getProgress,
}: Props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const dispatch = useDispatch();
  const isAppOnline = useSelector(isAppOnlineSelector);
  const navigateController = useSelector(navigateSelector);
  const loadingChecklist = useSelector(loadingChecklistSelector);
  const activitiesState = useSelector(checklistsStateSelector);
  const activityTypesId = data[0]?.ActivityTypesId || "";
  const loading = loadingChecklist[activityTypesId] || false;
  const [checklist, setChecklist] = React.useState<IActivityItem[]>([]);
  const [progress, setProgress] = React.useState<IProgress>(
    calcProgress(data)
  );
  const [checkAll, setCheckAll] = React.useState(
    calcProgress(data).pending === 0
  );
  const disableSubmit =
    loading ||
    (!navigateController.hasChanges &&
      !activitiesState.syncError[activityTypesId]);

  React.useEffect(() => {
    const progress = calcProgress(data);
    const newData = data.map((e) => ({ ...e }));
    setChecklist(newData);
    setProgress(calcProgress(data));
    setCheckAll(progress.pending === 0);
    getProgress && getProgress(progress);
    // eslint-disable-next-line
  }, [data]);

  const handleSubmit = () => {
    onSubmit(checklist);
    dispatch(
      setNavigateController({
        navigateController: { hasChanges: false, saveChanges: false },
      })
    );
  };

  React.useEffect(() => {
    if (navigateController.path && navigateController.saveChanges === true) {
      handleSubmit();
      dispatch(
        setNavigateController({
          navigateController: {
            ...navigateController,
            hasChanges: false,
            saveChanges: false,
          },
        })
      );
    }
    // eslint-disable-next-line
  }, [navigateController]);

  const getItems = (items: IActivityItem[], active: boolean) => {
    if (items.length > 0) {
      return items.map((item) => (
        <Grid
          item
          xs={12}
          key={item.planActivityId}
          sx={{ paddingTop: "0px !important" }}
        >
          <CheckItem
            item={item}
            active={active}
            isCustom={isCustom}
            handler={handleChange}
          />
        </Grid>
      ));
    } else {
      return (
        <Grid item xs={12}>
          <Typography variant="body1">{"Sin items"}</Typography>
        </Grid>
      );
    }
  };

  const handleCheckAll = () => {
    const newValue = !checkAll;
    setCheckAll(newValue);
    const newChecklist = [...checklist];
    newChecklist.forEach((e) => {
      e.checked = newValue;
    });
    setChecklist(newChecklist);
    setProgress(calcProgress(newChecklist));
    getProgress && getProgress(calcProgress(newChecklist), newChecklist);
    !navigateController.hasChanges &&
      dispatch(
        setNavigateController({ navigateController: { hasChanges: true } })
      );
  };

  const handleChange = (item: IActivityItem) => {
    const newChecklist = [...checklist];
    const index = newChecklist.findIndex(
      (e) => e.planActivityId === item.planActivityId
    );
    newChecklist[index] = { ...item };
    setChecklist(newChecklist);
    setProgress(calcProgress(newChecklist));
    getProgress && getProgress(calcProgress(newChecklist), newChecklist);
    !navigateController.hasChanges &&
      dispatch(
        setNavigateController({ navigateController: { hasChanges: true } })
      );
    if (!item.checked) setCheckAll(false);
  };

  return (
    <>
      {showProgress && (
        <Box component={Paper} px={2} py={1} mb={1}>
          <ProgressCard title={title} progress={progress} />
        </Box>
      )}
      <Box component={Paper} p={2}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              {!showProgress && (
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  <Typography variant="h5">{title}</Typography>
                  <ActionButton
                    onClick={handleSubmit}
                    loading={loading}
                    disabled={disableSubmit}
                    startIcon={!isMobile && <SaveIcon />}
                    variant="contained"
                  >
                    {isMobile ? <SaveIcon /> : t("labels.save")}
                  </ActionButton>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                mt={1}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckAll}
                      checked={checkAll}
                      color="default"
                      icon={<CheckAllIcon />}
                      indeterminate={checkAll}
                    />
                  }
                  label={
                    <Typography variant="h6" sx={{ opacity: 0.8 }}>
                      {t("labels.earrings")}
                    </Typography>
                  }
                />
                {showProgress && (
                  <Box>
                    <ActionButton
                      onClick={handleSubmit}
                      loading={loading}
                      disabled={disableSubmit}
                      startIcon={!isMobile && <SaveIcon />}
                      variant="contained"
                    >
                      {isMobile ? <SaveIcon /> : t("labels.save")}
                    </ActionButton>
                  </Box>
                )}
              </Grid>
              {getItems(
                checklist.filter((f) => f.checked === false),
                true
              )}
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ opacity: 0.8 }} mt={2}>
                  {t("labels.completed")}
                </Typography>
              </Grid>
              {getItems(
                checklist.filter((f) => f.checked === true),
                false
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {!hideAddNote && isAppOnline && <NoteFab />}
    </>
  );
};

export default CheckList;
