import React from "react";
import { TextField, Box, Autocomplete } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { filtersSelector, masterdataSelector } from "../../redux/admin/masterdata/selectors";
import { setFilteredOptions, setFilters } from "../../redux/admin/masterdata/actions";
import { useTranslation } from "react-i18next";

const DivisionSelector = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filters = useSelector(filtersSelector);
  const masterData = useSelector(masterdataSelector);
  const { divisions, countries, stores } = masterData;

  const handleChange = (value: string) => {
    const filteredDivisions = [value];
    const filteredCountries = countries.filter((c) => c.divisionId === value);
    const filteredStores = stores.filter((s) => s.divisionId === value);
    dispatch(setFilters({ filters: { division: value } }));
    dispatch(
      setFilteredOptions({
        options: {
          divisions: filteredDivisions,
          countries: filteredCountries,
          stores: filteredStores,
        },
      })
    );
  };

  return (
    <Box mb={1} mr={2}>
      <Autocomplete
        disablePortal
        id="division-selector"
        value={filters.division || null}
        options={divisions}
        getOptionLabel={(option) => t(`filters.${option}`)}
        renderInput={(params) => (
          <TextField variant="standard" {...params} label={t("labels.division")} />
        )}
        onChange={(e, value) => value && handleChange(value)}
        sx={{ width: 150 }}
        size="small"
      />
    </Box>
  );
};

export default DivisionSelector;
