import { IEmployeePositioningItem } from "../types";

export const mock = 0;

export const employee_positioning: IEmployeePositioningItem[] = [
  {
    country: "AR",
    storeAcronym: "OLI",
    personId: 557446,
    personName: "Mariana Avendaño",
    businessDay: "2024-09-03",
    presence: {
      worked: true,
      timeFrames: [
        {
          idFrame: 344332,
          startDay: "2024-09-03 08:00",
          endDay: "2024-09-03 12:30",
          location: {
            name: "APOYO DINAMICO",
            shortName: "APO",
            category: "APOYO DINAMICO",
          },
        },
        {
          idFrame: 344332,
          startDay: "2024-09-03 12:30",
          endDay: "2024-09-03 18:30",
          location: {
            name: "DT ORDR TRAKER",
            shortName: "DTO",
            category: "DT ORDR TRAKER",
          },
        },
      ],
    },
  },
  {
    country: "AR",
    storeAcronym: "OLI",
    personId: 557446,
    personName: "JJR Carlos",
    businessDay: "2024-09-03",
    presence: {
      worked: true,
      timeFrames: [
        {
          idFrame: 344332,
          startDay: "2024-09-03 6:00",
          endDay: "2024-09-03 14:30",
          location: {
            name: "INITIATOR",
            shortName: "INI",
            category: "PRODUCCION",
          },
        },
        {
          idFrame: 344332,
          startDay: "2024-09-03 14:30",
          endDay: "2024-09-03 20:00",
          location: {
            name: "DT ORDR TRAKER",
            shortName: "DTO",
            category: "DT ORDR TRAKER",
          },
        },
      ],
    },
  },
  {
    country: "AR",
    storeAcronym: "OLI",
    personId: 557446,
    personName: "Ulrich VL",
    businessDay: "2024-09-03",
    presence: {
      worked: true,
      timeFrames: [
        {
          idFrame: 344332,
          startDay: "2024-09-03 18:00",
          endDay: "2024-09-03 20:00",
          location: {
            name: "DT RUNNER",
            shortName: "RUN",
            category: "DT RUNNER",
          },
        },
        {
          idFrame: 344332,
          startDay: "2024-09-03 20:30",
          endDay: "2024-09-04 00:00",
          location: {
            name: "APOYO DINAMICO",
            shortName: "APO",
            category: "APOYO DINAMICO",
          },
        },
      ],
    },
  },
];
