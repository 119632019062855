import React from "react";
import { Box, Checkbox, FormControlLabel, IconButton, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { IActivityItem, ICustomChecklistItem } from "../types";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { FlexBox, SecondaryText } from "../styles";
import { useDispatch, useSelector } from "react-redux";
import { navigateSelector } from "../redux/app/selectors";
import { setNavigateController } from "../redux/app/actions";

interface Props {
  data: ICustomChecklistItem;
  disabled?: boolean;
  onClick: (action: "edit" | "delete") => void;
}

const CustomChecklistPreview = ({ data, disabled, onClick }: Props) => {
  const dispatch = useDispatch();
  const navigateController = useSelector(navigateSelector);
  const [checklist, setChecklist] = React.useState<Partial<IActivityItem>[]>([]);

  React.useEffect(() => {
    const newData = data.checklist.map((e) => ({ ...e }));
    setChecklist(newData);
    // eslint-disable-next-line
  }, [data]);

  React.useEffect(() => {
    if (navigateController.path && navigateController.saveChanges === true) {
      dispatch(
        setNavigateController({
          navigateController: { ...navigateController, hasChanges: false, saveChanges: false },
        })
      );
    }
    // eslint-disable-next-line
  }, [navigateController]);

  return (
    <Box component={Paper} p={2} sx={{ opacity: disabled ? 0.6 : 1 }}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} display={"flex"} justifyContent={"space-between"} mb={1}>
              <Box>
                <Typography variant="h5">{data.title}</Typography>
                <SecondaryText variant="body1">{data.description}</SecondaryText>
              </Box>
              <FlexBox sx={{ alignSelf: "start" }}>
                <IconButton disabled={disabled} onClick={() => onClick("edit")}>
                  <EditIcon color="primary" />
                </IconButton>
                <IconButton disabled={disabled} onClick={() => onClick("delete")}>
                  <DeleteIcon color="primary" />
                </IconButton>
              </FlexBox>
            </Grid>
            {checklist.map((item, index) => (
              <Grid item xs={12} key={`${item.planActivityId}-${index}`}>
                <FormControlLabel
                  control={<Checkbox checked={false} />}
                  label={item.description || ""}
                  sx={{ cursor: "default" }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomChecklistPreview;
