import { useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from "material-react-table";
import { useSelector } from "react-redux";
import { employee_positioning } from "../../helpers/mocks";
import { scheduleRangeSelector } from "../../redux/admin/schedules/selectors";
import { IEmployeePositioningItem, ITimeFrameItem } from "../../types";
import TimeFrame from "./TimeFrame";
import { format, isAfter, isBefore } from "date-fns";
import { Typography } from "@mui/material";
import { BoldTypography } from "../../styles";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { MRT_Localization_PT } from "material-react-table/locales/pt";
import { MRT_Localization_FR } from "material-react-table/locales/fr";
import EmployeePositioningFormModal from "../../components/modals/EmployeePositioningFormModal";

function getLocalization(language: string) {
  switch (language) {
    case "en":
      return MRT_Localization_EN;
    case "fr":
      return MRT_Localization_FR;
    case "pt":
      return MRT_Localization_PT;
    default:
      return MRT_Localization_ES;
  }
}

function getRange(timeFrames: ITimeFrameItem[]) {
  let start: Date | null = null;
  let end: Date | null = null;

  timeFrames.forEach((time) => {
    if (start === null || isBefore(new Date(time.startDay), start)) start = new Date(time.startDay);
    if (end === null || isAfter(new Date(time.endDay), end)) end = new Date(time.endDay);
  });

  if (start && end) return `${format(start, "HH:mm")} - ${format(end, "HH:mm")}`;
  else return "";
}

function formattedData(data: IEmployeePositioningItem[]) {
  let result: { [key: string]: IEmployeePositioningItem[] } = {};

  data.forEach((employee) => {
    employee.presence.timeFrames.forEach((t) => {
      const category = t.location.category;
      if (!result[category]) result[category] = [];
      result[category].push({
        ...employee,
        category,
        timeRange: getRange(employee.presence.timeFrames),
      });
    });
  });

  return result;
}

const MRTable = () => {
  const { t, i18n } = useTranslation();
  const scheduleRange = useSelector(scheduleRangeSelector);
  const [openForm, setOpenForm] = useState(false);

  const positioning = useMemo(() => {
    const dataByCategories = formattedData(employee_positioning);
    const result: IEmployeePositioningItem[] = [];

    Object.keys(dataByCategories).forEach((key) => {
      const row: Partial<IEmployeePositioningItem> = {
        category: key,
        subRows: [...dataByCategories[key]],
      };
      result.push(row as IEmployeePositioningItem);
    });

    return result;
  }, [employee_positioning]);

  const columns = useMemo<MRT_ColumnDef<IEmployeePositioningItem>[]>(() => {
    const result: MRT_ColumnDef<IEmployeePositioningItem>[] = [
      {
        accessorKey: "category",
        header: "",
        size: 10,
        Cell: ({ row }) =>
          row.original.personName ? (
            <Typography variant="caption">{row.original.personName}</Typography>
          ) : (
            <BoldTypography variant="body2">{row.original.category}</BoldTypography>
          ),
      },
      {
        accessorKey: "timeRange",
        header: "",
        size: 10,
        Cell: ({ cell }) => <Typography variant="caption">{cell.getValue<string>()}</Typography>,
      },
      {
        accessorKey: "dummy",
        header: "",
        size: 0,
      },
    ];

    scheduleRange.forEach((time, index) => {
      const column: MRT_ColumnDef<IEmployeePositioningItem> = {
        accessorKey: `${time}`,
        header: `${time}:00`,
        size: 10,
        muiTableHeadCellProps: {
          sx: {
            textAlign: "center",
            fontWeight: 300,
            // borderLeft: '0.5px solid rgba(0, 0, 0, .3)',
          },
        },
        muiTableBodyCellProps: {
          sx: {
            padding: 0,
            margin: 0,
            zIndex: 100 - index,
            overflow: "auto",
            // borderLeft: '0.5px solid rgba(0, 0, 0, .3)',
          },
        },
        Cell: ({ cell, row }) => (
          <TimeFrame
            index={index}
            time={time}
            timeFrames={row?.original?.presence?.timeFrames || []}
            category={row?.original?.category || ""}
            businessDay={row?.original?.businessDay || ""}
          />
        ),
      };
      result.push(column);
    });

    return result;
  }, [scheduleRange]);

  const handleRowClick = (row: IEmployeePositioningItem) => {
    console.log("handleRowClick", { row });
    setOpenForm(true);
  };

  const handleSubmit = () => {
    setOpenForm(false);
  };

  const table = useMaterialReactTable({
    columns,
    data: positioning,
    enableExpandAll: true, //hide expand all double arrow in column header
    enableExpanding: true,
    filterFromLeafRows: true, //apply filtering to all rows instead of just parent rows
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: false,
    enableDensityToggle: false,
    enableColumnOrdering: false,
    getSubRows: (row) => row.subRows, //default
    initialState: { expanded: true, density: "compact" }, //expand all rows by default
    paginateExpandedRows: false, //When rows are expanded, do not count sub-rows as number of rows on the page towards pagination
    localization: getLocalization(i18n.language),
    // actions
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => handleRowClick(row.original),
      sx: {
        cursor: "pointer",
        bgcolor: (theme) =>
          !row.original.personName
            ? theme.palette.mode === "dark"
              ? "#2b2b2b"
              : grey[100]
            : "inherit",
      },
    }),
    // styles
    displayColumnDefOptions: {
      "mrt-row-expand": {
        header: "",
        size: 10,
      },
    },
    muiTableHeadCellProps: {
      sx: {
        padding: 0,
        margin: 0,
      },
    },
    muiTableBodyCellProps: {
      sx: {
        padding: 0,
        margin: 0,
      },
    },
    muiExpandButtonProps: ({ row }) => ({
      sx: { display: row.original.personName ? "none" : undefined },
    }),
    muiSearchTextFieldProps: {
      placeholder: t("labels.searchByCategory").toString(),
    },
  });

  return (
    <>
      <MaterialReactTable table={table} />
      <EmployeePositioningFormModal open={openForm} onSubmit={handleSubmit} />
    </>
  );
};

export default MRTable;
