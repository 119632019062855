import { useState } from "react";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { ICommentItem, IManager, INoteItem, IObjectiveItem } from "../../types";
import {
  Box,
  Chip,
  DialogActions,
  DialogContentText,
  DialogTitle,
  IconButton,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { ActionButton, FlexBox } from "../../styles";
import { useTranslation } from "react-i18next";
import { getObjectiveDescription } from "../../helpers/functions";
import CommentList from "../CommentList";
import { useSelector } from "react-redux";
import { isLoadingCommentSelector } from "../../redux/activities/objectives/selectors";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import { useDispatch } from "react-redux";
import { deleteObjectiveRequested } from "../../redux/activities/objectives/actions";
import { differenceInMinutes } from "date-fns";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const objTypeChip: SxProps<Theme> = (theme) => ({
  bgcolor: "secondary.light",
  px: 0.5,
  color: theme.palette.mode === "dark" ? "black" : undefined,
  fontSize: "1.05rem",
});

export interface NoteFormData extends Partial<INoteItem> {
  [key: string]: number | string | boolean | IManager | ICommentItem[] | undefined;
}

const commentContailer: SxProps = {
  maxHeight: "calc(100vh - 320px)",
  width: 700,
  overflow: "auto",
  mt: 1.5,
  pr: 2,
};

const titleStyle: SxProps = {
  textAlign: "center",
  color: "primary",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

interface Props {
  open: boolean;
  objectiveData: IObjectiveItem | null;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ObjectiveModal = ({ open, objectiveData, setOpen }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDeleting, setIsDeleting] = useState(false);
  const loadingComments = useSelector(isLoadingCommentSelector);
  const deleteEnabled = objectiveData?.createdDate
    ? differenceInMinutes(new Date(), new Date(objectiveData.createdDate)) <= 15
    : false;

  const handleClose = (reason?: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") return null;
    setOpen(false);
    setIsDeleting(false);
  };

  const handleDelete = () => {
    if (objectiveData?.id) {
      dispatch(deleteObjectiveRequested(objectiveData));
      setOpen(false);
      setIsDeleting(false);
    }
  };

  /** RENDER CONTENT */

  const renderContent = () => {
    return (
      <>
        <DialogContent>
          {objectiveData && (
            <Box>
              <FlexBox sx={{ justifyContent: "space-between" }}>
                <Chip
                  label={getObjectiveDescription(objectiveData, "type")}
                  size="small"
                  sx={objTypeChip}
                />
                {deleteEnabled && (
                  <IconButton color={"primary"} onClick={() => setIsDeleting(true)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </FlexBox>
              <Typography variant="h5" sx={{ fontWeight: 400, mt: 0.5 }}>
                {objectiveData?.objectiveTypeId === "OT"
                  ? objectiveData.objectiveDescription
                  : getObjectiveDescription(objectiveData, "item")}
              </Typography>
              <Typography variant="h6" sx={{ fontSize: "1.1rem", opacity: 0.6, fontWeight: 400 }}>
                {objectiveData?.objectiveTypeId !== "OT"
                  ? objectiveData?.objectiveDescription
                  : null}
              </Typography>
              <Box sx={commentContailer}>
                <CommentList
                  item="objective"
                  itemId={objectiveData?.id || 0}
                  data={objectiveData?.comments || []}
                  loading={loadingComments}
                />
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <ActionButton
            variant="outlined"
            onClick={() => handleClose()}
            sx={{ color: (theme) => theme.palette.text.primary, mx: 2, mb: 1, px: 2 }}
          >
            {t("labels.close")}
          </ActionButton>
        </DialogActions>
      </>
    );
  };

  const renderDeleteMsg = () => {
    return (
      <>
        <DialogTitle sx={titleStyle} color={"primary"}>
          <Box flexGrow={1}>{t("pages.objectives.deleteObjective")}</Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="description">
            {t("pages.objectives.deleteObjectiveDescription")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ActionButton
            variant="outlined"
            onClick={() => setIsDeleting(false)}
            sx={{ color: (theme) => theme.palette.text.primary }}
          >
            {t("labels.cancel")}
          </ActionButton>
          <ActionButton variant="contained" onClick={handleDelete}>
            {t("labels.confirm")}
          </ActionButton>
        </DialogActions>
      </>
    );
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={(event, reason) => handleClose(reason)}
      aria-describedby="description"
      maxWidth={"lg"}
    >
      {isDeleting ? renderDeleteMsg() : renderContent()}
    </Dialog>
  );
};

export default ObjectiveModal;
