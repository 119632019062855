import React from "react";
import {
  Box,
  Grid,
  ListItem,
  ListItemText,
  Paper,
  SxProps,
  Typography,
  styled,
} from "@mui/material";
import DivisionIcon from "@mui/icons-material/Public";
import CountryIcon from "@mui/icons-material/Place";
import StoreIcon from "@mui/icons-material/Storefront";
import { t } from "i18next";
import { IAppFilters } from "../../types";
import IconAvatar from "../IconAvatar";
import { FlexBox } from "../../styles";

const lgIcon: SxProps = {
  width: 35,
  height: 35,
  ml: 0.1,
};

const StyleTitle = styled(Typography)({
  fontWeight: 600,
});

interface SCProps {
  icon: JSX.Element;
  description?: string;
  dense?: boolean;
  title: string;
}

const StatCard = ({ icon, description, dense, title }: SCProps) => {
  return (
    <ListItem alignItems="center" sx={{ paddingLeft: 0, p: dense ? 0 : undefined }}>
      <FlexBox>
        <IconAvatar sx={{ mr: 1, width: 48, height: 48 }}>{icon}</IconAvatar>
        <ListItemText
          primary={<StyleTitle>{title}</StyleTitle>}
          secondary={
            <Typography sx={{ opacity: 0.6, fontWeight: 500 }}>{description || "-"}</Typography>
          }
        />
      </FlexBox>
    </ListItem>
  );
};

interface Props {
  dense?: boolean;
  filters: IAppFilters;
  title?: string;
}

const StoreDetailsCard = ({ dense, filters, title }: Props) => {
  const { division, country, store } = filters;
  return (
    <Box component={Paper} px={2} my={1}>
      {title && <Typography variant="h6">{title}</Typography>}
      <Grid container spacing={1}>
        <Grid item xs={4} sx={{ paddingTop: "0px !important" }}>
          <StatCard
            title={t("labels.division")}
            description={division && t(`filters.${division}`).toString()}
            dense={dense}
            icon={<DivisionIcon sx={lgIcon} color="primary" />}
          />
        </Grid>
        <Grid item xs={4} sx={{ paddingTop: "0px !important" }}>
          <StatCard
            title={t("labels.country")}
            description={country?.label && t(`filters.${country?.label}`).toString()}
            dense={dense}
            icon={<CountryIcon sx={lgIcon} color="primary" />}
          />
        </Grid>
        <Grid item xs={4} sx={{ paddingTop: "0px !important" }}>
          <StatCard
            title={t("labels.store")}
            description={store?.storeAcronym}
            dense={dense}
            icon={<StoreIcon sx={lgIcon} color="primary" />}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default StoreDetailsCard;
