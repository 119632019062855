import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  Theme,
  useMediaQuery,
} from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AllNotesIcon from "@mui/icons-material/LibraryBooks";
import PrivateIcon from "@mui/icons-material/PendingActions";
import PublicIcon from "@mui/icons-material/Storefront";
import CalendarIcon from "@mui/icons-material/DateRange";
import AddIcon from "@mui/icons-material/Add";
import { ActivityType, INoteItem, NoteState } from "../../types";
import NoteList from "../../components/NoteList";
import { format, startOfDay, subDays } from "date-fns";
import { useTranslation } from "react-i18next";
import { ActionButton, FlexBox } from "../../styles";
import DateRangeModal from "../../components/modals/DateRangeModal";
import { useSelector } from "react-redux";
import {
  lastRequestSelector,
  loadingNoteSelector,
  notesDataSelector,
  notesErrorSelector,
  requestingNotesSelector,
} from "../../redux/activities/notes/selectors";
import { useDispatch } from "react-redux";
import {
  getNotesRequested,
  setSelectedNote,
  toggleNoteModal,
  updateNoteRequested,
} from "../../redux/activities/notes/actions";
import { endOfDay } from "date-fns/esm";
import {
  compareNotesDate,
  compareNotesPriority,
  getISOString,
} from "../../helpers/functions";
import { isAppOnlineSelector } from "../../redux/app/selectors";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import ScreenLoader from "../../components/ScreenLoader";

type SelectionType = "all" | "shift" | "store";

type ElementsType = { [key: string]: INoteItem[] };

function getVariant(value: SelectionType, selected: SelectionType) {
  if (value === selected) return "contained";
  return "outlined";
}

function formattedDate(fromDate: Date, toDate: Date) {
  const pattern = "d MMM";
  return `${format(fromDate, pattern)} - ${format(
    toDate,
    pattern
  )} , ${fromDate.getFullYear()}`;
}

export const initialNotesDates = {
  from: subDays(new Date(), 6),
  to: new Date(),
};

/**
 * Obtiene todas las notas en un rango máximo de 7 días.
 * Cada nota tiene contiene un array de @comments que operan en separado.
 * @state indica el estado actual de la nota: "pending" | "active" | "done".
 * @priority indica el nivles de criticidad/prioridad de una nota.
 * @privacy indica si una es visible para todo el restaurante o solo para el turno creado.
 * Operaciones: Alta/Baja/Modificación
 * @activityType NTE
 */

const NoteSummary = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );
  const dispatch = useDispatch();
  const isAppOnline = useSelector(isAppOnlineSelector);
  const notes = useSelector(notesDataSelector);
  const loadingNotes = useSelector(loadingNoteSelector);
  const requestingNotes = useSelector(requestingNotesSelector);
  const notesError = useSelector(notesErrorSelector);
  const lastRequest = useSelector(lastRequestSelector);
  const [selected, setSelected] = useState<SelectionType>("all");
  const [openCalendar, setOpenCalendar] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [lastSelectedList, setLastSelectedList] = useState<NoteState>();
  const [from, setFrom] = useState(
    lastRequest.from ? new Date(lastRequest.from) : initialNotesDates.from
  );
  const [to, setTo] = useState(
    lastRequest.to ? new Date(lastRequest.to) : initialNotesDates.to
  );

  const loadingPendingList =
    loadingNotes && refreshData && lastSelectedList === "pending";
  const loadingActiveList =
    loadingNotes && refreshData && lastSelectedList === "active";
  const loadingDoneList =
    loadingNotes && refreshData && lastSelectedList === "done";

  const handleSelection = (value: SelectionType) => {
    setElements(generateElements(notes, value));
    setSelected(value);
  };

  const handleDate = (fromDate?: Date, toDate?: Date) => {
    setOpenCalendar(false);
    if (fromDate && toDate) {
      setRefreshData(true);
      setFrom(fromDate);
      setTo(toDate);
      dispatch(
        getNotesRequested({
          from: getISOString(startOfDay(fromDate)),
          to: getISOString(endOfDay(toDate)),
        })
      );
    }
  };

  const handleClick = (note?: INoteItem) => {
    if (!requestingNotes) {
      setRefreshData(true);
      setLastSelectedList(note?.state);
      dispatch(setSelectedNote({ note: note || null }));
      dispatch(toggleNoteModal());
    }
  };

  const handleAddNote = () => {
    setRefreshData(true);
    setLastSelectedList("pending");
    dispatch(setSelectedNote({ note: null }));
    dispatch(toggleNoteModal());
  };

  const generateElements = (
    data: INoteItem[],
    filter: string
  ): ElementsType => {
    let result: ElementsType = {};
    let filteredData: INoteItem[] = [];

    switch (filter) {
      case "store":
        filteredData = data
          .filter((n) => n.private === false)
          .sort(compareNotesPriority);
        break;
      case "shift":
        filteredData = data
          .filter((n) => n.private === true)
          .sort(compareNotesPriority);
        break;
      default:
        filteredData = data.sort(compareNotesPriority);
        break;
    }

    result["pending"] = filteredData.filter((e) => e.state === "pending");
    result["active"] = filteredData.filter((e) => e.state === "active");
    result["done"] = filteredData
      .filter((e) => e.state === "done")
      .sort(compareNotesDate);

    return result;
  };

  /**
   * Drag and drop actions
   * @elements state de @notes para actualizar el tablero usando dnd
   * @refreshData controla el re-rendender de @elements al actualizar @notes
   */

  const [elements, setElements] = useState<ElementsType>(
    generateElements(notes, selected)
  );

  const removeFromList = (list: INoteItem[], index: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(index, 1);

    return { removed, result };
  };

  const addToList = useCallback(
    (list: INoteItem[], index: number, element: INoteItem) => {
      const result = Array.from(list);
      result.splice(index, 0, element);
      return result;
    },
    []
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }
      setRefreshData(false);
      const listCopy: ElementsType = { ...elements };
      const sourceList = listCopy?.[result.source.droppableId];
      const { removed: removedElement, result: newSourceList } = removeFromList(
        sourceList,
        result.source.index
      );
      listCopy[result.source.droppableId] = newSourceList;
      const destinationList = listCopy[result.destination.droppableId];
      listCopy[result.destination.droppableId] = addToList(
        destinationList,
        result.destination.index,
        {
          ...removedElement,
          state: result.destination.droppableId as NoteState,
        }
      );

      setElements(listCopy);
      if (result.source.droppableId !== result.destination.droppableId)
        dispatch(
          updateNoteRequested({
            ...removedElement,
            activityTypesId: ActivityType.NOTES,
            lastUpdateDate: getISOString(),
            state: result.destination.droppableId as NoteState,
          })
        );
    },
    // eslint-disable-next-line
    [elements, addToList]
  );

  useEffect(() => {
    if (refreshData || (notesError && !refreshData))
      setElements(generateElements(notes, selected));
    // eslint-disable-next-line
  }, [notes, notesError]);

  return (
    <>
      <Container maxWidth={false}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Grid
            container
            spacing={3}
            sx={{ opacity: requestingNotes ? 0.5 : 1 }}
          >
            <Grid item xs={12} mb={-2}>
              <FlexBox sx={{ justifyContent: "space-between" }}>
                <FlexBox>
                  <ButtonGroup disabled={!isAppOnline || requestingNotes}>
                    <Button
                      variant={getVariant("all", selected)}
                      startIcon={<AllNotesIcon />}
                      onClick={() => handleSelection("all")}
                    >
                      {t("labels.allNotes")}
                    </Button>
                    <Button
                      variant={getVariant("store", selected)}
                      startIcon={<PublicIcon />}
                      onClick={() => handleSelection("store")}
                    >
                      {isMobile
                        ? t("labels.store")
                        : t("labels.restaurantNotes")}
                    </Button>
                    <Button
                      variant={getVariant("shift", selected)}
                      startIcon={<PrivateIcon />}
                      onClick={() => handleSelection("shift")}
                    >
                      {isMobile ? t("labels.shift") : t("labels.shiftNotes")}
                    </Button>
                  </ButtonGroup>
                </FlexBox>
                <FlexBox>
                  <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
                    <Input
                      id="standard-adornment-password"
                      value={formattedDate(from, to)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setOpenCalendar(true)}
                            disabled={!isAppOnline || requestingNotes}
                          >
                            <CalendarIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      disabled={!isAppOnline || requestingNotes}
                    />
                  </FormControl>
                  <ActionButton
                    variant="contained"
                    size="medium"
                    sx={{ ml: 2, width: 100 }}
                    onClick={handleAddNote}
                    loading={requestingNotes}
                    disabled={!isAppOnline || requestingNotes}
                    startIcon={
                      <AddIcon sx={{ width: 20, height: 20, mr: -0.5 }} />
                    }
                  >
                    {t("labels.note").toLocaleUpperCase()}
                  </ActionButton>
                </FlexBox>
              </FlexBox>
            </Grid>
            <Grid item xs={4}>
              <NoteList
                title={t("labels.pending")}
                notes={elements["pending"]}
                state="pending"
                loading={loadingPendingList}
                onClick={handleClick}
              />
            </Grid>
            <Grid item xs={4}>
              <NoteList
                title={t("labels.inProgress")}
                notes={elements["active"]}
                state="active"
                loading={loadingActiveList}
                onClick={handleClick}
              />
            </Grid>
            <Grid item xs={4}>
              <NoteList
                title={t("labels.done")}
                notes={elements["done"]}
                state="done"
                loading={loadingDoneList}
                onClick={handleClick}
              />
            </Grid>
          </Grid>
        </DragDropContext>
      </Container>
      <DateRangeModal
        open={openCalendar}
        onSubmit={handleDate}
        dateRange={{ from, to }}
      />
      <ScreenLoader loading={requestingNotes} />
    </>
  );
};

export default NoteSummary;
