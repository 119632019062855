import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { IObjectiveItem } from "../../types";
import { Box, Grid, MenuItem, SxProps, TextField, Typography } from "@mui/material";
import { ActionButton, FlexBox } from "../../styles";
import { useTranslation } from "react-i18next";
import ErrorIcon from "@mui/icons-material/Error";
import { useSelector } from "react-redux";
import { scheduleRangeSelector } from "../../redux/admin/schedules/selectors";
import { timeIntToLabel } from "../../helpers/functions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const titleStyle: SxProps = {
  textAlign: "center",
  color: "primary",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const MenuProps = {
  slotProps: {
    paper: {
      sx: { maxHeight: 160 },
    },
  },
};

interface IFormData {
  [key: string]: string | number | null;
  category: string;
  shortName: string;
  personName: string;
  startDay: number;
  endDay: number | string;
}

interface Props {
  data?: IObjectiveItem;
  open: boolean;
  onSubmit: (newData?: Partial<IObjectiveItem>, isDeleting?: boolean) => void;
}

export const EmployeePositioningFormModal = ({ data, open, onSubmit }: Props) => {
  const { t } = useTranslation();
  const scheduleRange = useSelector(scheduleRangeSelector);

  const initialData = React.useMemo(() => {
    return {
      category: "",
      personName: "",
      shortName: "",
      startDay: scheduleRange[0],
      endDay: "",
    };
  }, [scheduleRange]);

  const [formData, setFormData] = React.useState<IFormData>({
    ...initialData,
    startDay: scheduleRange[0],
  });
  const [error, setError] = React.useState<string>();
  const isEdit = Boolean(data);

  const endDayOptions = React.useMemo(() => {
    if (formData.startDay === 23) return scheduleRange.filter((e) => e < scheduleRange[0]);
    else if (formData.startDay < scheduleRange[0])
      return scheduleRange.filter((e) => e > formData.startDay && e < scheduleRange[0]);
    else return scheduleRange.filter((e) => e > formData.startDay);
  }, [formData.startDay, scheduleRange]);

  React.useEffect(() => {
    if (data) {
      // todo
    }
  }, [data]);

  const handleChange = (field: string, value: string | number) => {
    let newData = { ...formData };
    newData[field] = value;
    // if (field === "startDay") newData["endDay"] = (value as number) + 1;
    if (field === "startDay") newData["endDay"] = "";

    setFormData(newData);
    setError(undefined);
  };

  const handleSubmit = () => {
    // todo
    handleReset();
  };

  const handleClose = () => {
    onSubmit();
    handleReset();
  };

  const handleReset = () => {
    setFormData(initialData);
    setError(undefined);
  };

  /** RENDERS */

  const OPTIONS = ["A", "B", "C"];

  const renderContent = () => {
    return (
      <>
        <DialogTitle sx={titleStyle}>
          <Box flexGrow={1} sx={isEdit ? { ml: 3 } : null}>
            {/* {isEdit ? t("pages.objectives.editObjective") : t("pages.objectives.newObjective")} */}
            {"Posicionamiento de empleado"}
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="description">
            {/* {t("pages.objectives.newObjectiveDescription")} */}
            {"Completa el formulario para cargar o modificar el posicionamiento."}
          </DialogContentText>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={6}>
              <TextField
                error={Boolean(error)}
                fullWidth
                id="category"
                select
                label={"Category"}
                size="small"
                value={formData.category}
                onChange={(e) => handleChange("category", e.target.value)}
                sx={{ mb: 2 }}
                disabled={isEdit}
              >
                {OPTIONS.map((o, index) => (
                  <MenuItem value={o} key={index}>
                    {o}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={6}>
              <TextField
                error={Boolean(error)}
                fullWidth
                id="shortName"
                label={"Short name"}
                size="small"
                value={formData.category}
                onChange={(e) => handleChange("shortName", e.target.value)}
                sx={{ mb: 2 }}
                disabled={true}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                error={Boolean(error)}
                fullWidth
                id="personName"
                select
                label={"Person name"}
                size="small"
                value={formData.personName}
                onChange={(e) => handleChange("personName", e.target.value)}
                sx={{ mb: 2 }}
                disabled={isEdit}
              >
                {OPTIONS.map((o, index) => (
                  <MenuItem value={o} key={index}>
                    {o}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={3}>
              <TextField
                error={Boolean(error)}
                fullWidth
                id="startDay"
                select
                label={"Start hour"}
                size="small"
                value={formData.startDay}
                SelectProps={{ MenuProps }}
                // disabled={formData.startDay === ""}
                sx={{ mb: 2 }}
                onChange={(e) => handleChange("startDay", e.target.value)}
              >
                {scheduleRange.map((item, index) => (
                  <MenuItem value={item} key={index}>
                    {timeIntToLabel(item)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={3}>
              <TextField
                error={Boolean(error)}
                fullWidth
                id="endDay"
                select
                label={"End hour"}
                size="small"
                value={formData.endDay || ""}
                SelectProps={{ MenuProps }}
                // disabled={formData.startDay === ""}
                sx={{ mb: 2 }}
                onChange={(e) => handleChange("endDay", e.target.value)}
              >
                {endDayOptions.map((item, index) => (
                  <MenuItem value={item} key={index}>
                    {timeIntToLabel(item)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
      </>
    );
  };

  const renderError = () => {
    if (error)
      return (
        <FlexBox ml={3}>
          <ErrorIcon color="error" sx={{ width: 15, height: 15 }} />
          <Typography variant="body2" color="error" ml={0.5}>
            {error}
          </Typography>
        </FlexBox>
      );
  };

  return (
    <Dialog
      fullWidth
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-describedby="description"
      maxWidth={"sm"}
      keepMounted={false}
    >
      <Box p={1}>
        {renderContent()}
        {renderError()}
        <DialogActions sx={{ mx: 2 }}>
          <ActionButton
            variant="outlined"
            onClick={handleClose}
            sx={{ color: (theme) => theme.palette.text.primary }}
          >
            {t("labels.cancel")}
          </ActionButton>
          <ActionButton variant="contained" onClick={handleSubmit} disabled={false}>
            {t("labels.confirm")}
          </ActionButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default EmployeePositioningFormModal;
